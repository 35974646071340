(function() {
    'use strict';

    angular
        .module('projectManagementApp')
        .controller('UserImportController', UserImportController);

    UserImportController.$inject = ['$scope', '$http', 'AlertService'];

    function UserImportController ($scope, $http, AlertService) {
        var vm = this;

        loadData();

        function loadData() {
            $http.get('/api/user-import/users').then(function (result) {
                vm.importUsers = result.data;
            }, function (result) {
                console.log(result);
                alert('Klarte ikke laste brukere. Prøv igjen og gi beskjed til din kontaktperson ' +
                    'i DevelopIT dersom problemet vedvarer.');
            });
        }

        $scope.showAddUserModal = function(user) {
            $scope.projectMember = {};
            $scope.projectMember.login = user.email;
            $scope.projectMember.firstName = user.name.split(' ').slice(0, -1).join(' ');
            $scope.projectMember.lastName = user.name.split(' ').slice(-1).join(' ');
            $scope.projectMember.email = user.email;
            $scope.projectMember.activated = true;
            $scope.projectMember.password = generateRandomPassword();
            $scope.projectMember.timesheetReminder = false;
            $scope.projectMember.externalSystem = 'FAMAC';
            $scope.projectMember.externalId = user.id;
            $scope.projectMember.ansvar = user.ansvar;
            $('#addUserModal').modal('show');
        };

        $scope.saveProjectMember = function() {
            $http({
                method: 'POST',
                url: 'api/members',
                data: $scope.projectMember
            }).then(function (response) {
                AlertService.success("Ekstern bruker opprettet.");
                loadData();
                $('#addUserModal').modal('hide');
            }, function (response) {
                alert("En feil oppstod. Sjekk loggen!");
                console.log(response);
                $('#addUserModal').modal('hide');
            });
        };

        function generateRandomPassword() {
            var text = "";
            var possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";

            for (var i = 0; i < 32; i++)
                text += possible.charAt(Math.floor(Math.random() * possible.length));

            return text;
        }
    }
})();
