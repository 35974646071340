(function() {
    'use strict';

    angular
        .module('projectManagementApp')
        .controller('GpsItemController', GpsItemController);

    GpsItemController.$inject = ['NgMap', '$http'];

    function GpsItemController (NgMap, $http) {
        var vm = this;

        vm.gpsItemHandledValue = null;

        loadData();

        NgMap.getMap().then(function(map) {
            vm.map = map;
        });

        vm.showInfoWindow = function(event, id) {
            angular.forEach(vm.gpsItems, function (value, key) {
                if (value.id === id)  {
                    vm.gpsItem = value;
                }
            });
            vm.map.showInfoWindow('infoWindow', this);
        };

        vm.flagGpsItem = function() {
            $http.post('/api/gpsitem/' + vm.gpsItem.id + "/flag").then(function(result) {
                loadData();
            });
        };

        vm.handleGpsItem = function() {
            $http.post('/api/gpsitem/' + vm.gpsItem.id + "/handled" + (vm.gpsItemHandledValue ? "?value=" + vm.gpsItemHandledValue : ""))
                .then(function(result) {
                    loadData();
                    vm.gpsItemHandledValue = null;
                });
        };

        function loadData() {
            $http.get('/api/gpsitem').then(function(result) {
                vm.gpsItems = result.data;
                angular.forEach(vm.gpsItems, function (value, key) {
                    if (value.flag) {
                        value.icon = 'content/images/molok_flagged.png';
                    } else {
                        value.icon = 'content/images/molok.png';
                    }
                });
            });
        }

    }
})();
