/* eslint-disable no-undef,angular/controller-as,angular/log */
(function() {
    'use strict';

    angular
        .module('projectManagementApp')
        .controller('MemberReportController', MemberReportController);

    MemberReportController.$inject = ['$scope', '$http'];

    function MemberReportController ($scope, $http) {
        var vm = this;

        vm.inAmount = true;
        vm.selectedYear = moment().year().toString();

        vm.ansvarFilter = {
            ansvar: ''
        };

        loadData();

        vm.refresh = function () {
            loadData();
        };

        function loadData() {
            $http.get('/api/member/report?inAmount=' + vm.inAmount + '&selectedYear=' + vm.selectedYear
                + '&selectedAnsvar=' + vm.ansvarFilter.ansvar).then(function (result) {
                vm.memberReport = result.data;
            }, function (result) {
                console.log(result);
                alert('Klarte ikke laste Mannskapsrapport. Prøv igjen og gi beskjed til din kontaktperson ' +
                    'i DevelopIT dersom problemet vedvarer.');
            });
        }

    }
})();
