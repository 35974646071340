(function () {
    'use strict';

    angular
        .module('projectManagementApp')
        .controller('ProjectReportController', ProjectReportController);

    ProjectReportController.$inject = ['$scope', '$http'];

    function ProjectReportController($scope, $http) {
        var vm = this;

        vm.inAmount = true;
        vm.showMemberHours = true;
        vm.showEquipmentHours = true;
        vm.projectStatus = 'all';
        vm.ansvarFilter = { ansvar: 'all' };
        vm.tag = 'all';
        vm.selectedYear = moment().year().toString();
        vm.selectedProjectGroup = 'all';
        vm.invoiceStatus = 'ALL';
        vm.invoiceableStatus = 'ALL';
        vm.showSubProjects = false;

        loadData();

        vm.refresh = function () {
            loadData();
        };

        $scope.updateAnsvarFilter = function (ansvar) {
            vm.ansvarFilter.ansvar = ansvar;
            loadData();
        };

        function loadData() {
            $http.get('/api/project/report?inAmount=' + vm.inAmount + '&selectedYear=' + vm.selectedYear +
                '&showMemberHours=' + vm.showMemberHours + '&showEquipmentHours=' + vm.showEquipmentHours +
                '&selectedAnsvar=' + vm.ansvarFilter.ansvar + '&projectStatus=' + vm.projectStatus +
                '&projectGroup=' + vm.selectedProjectGroup + '&tag=' + vm.tag + '&invoiceStatus=' + vm.invoiceStatus +
                '&showSubProjects=' + vm.showSubProjects + '&invoiceableStatus=' + vm.invoiceableStatus)
                .then(function (result) {
                    vm.projectReport = result.data;
                }, function (result) {
                    console.log(result);
                    alert('Klarte ikke laste Prosjektrapport. Prøv igjen og gi beskjed til din kontaktperson ' +
                        'i DevelopIT dersom problemet vedvarer.');
                });
        }

    }
})();
