(function() {
    'use strict';

    angular
        .module('projectManagementApp')
        .controller('InvoiceReportController', InvoiceReportController);

    InvoiceReportController.$inject = ['$scope', '$http'];

    function InvoiceReportController ($scope, $http) {
        var vm = this;

        // Chart properties for all charts.
        vm.labels = ['Ikke fakturert', 'Fakturert'];
        vm.chartColors = ['#f0ad4e', '#5cb85c'];

        // Find last day of previous month.
        // eslint-disable-next-line no-undef
        vm.lastDayOfPreviousMonth = moment()
            .subtract(1,'months')
            .endOf('month');

        // Initialize datepicker.
        // eslint-disable-next-line no-undef,angular/angularelement
        $('#datePicker').datetimepicker({
            "format": 'DD.MM.YYYY',
            "locale": 'nb',
            "defaultDate": vm.lastDayOfPreviousMonth
        });

        vm.setInvoiceDate = function () {
            // eslint-disable-next-line no-undef,angular/angularelement
            $("#datePicker").datetimepicker().on("dp.change", function (data) {
                loadData(data.date);
            });
        };

        // Do an initial load of data.
        loadData(vm.lastDayOfPreviousMonth);

        function loadData(invoiceDate) {
            $http.get('/api/invoice/report?date=' + invoiceDate.format("YYYY-MM-DD")).then(function (result) {
                vm.invoiceReports = result.data;
                vm.ansvars = [];
                angular.forEach(vm.invoiceReports, function(value, key){
                    vm.ansvars.push({
                        data: [value.notInvoicedAsLong, value.invoicedAsLong],
                        options: {
                            legend: {
                                display: true,
                                position: 'bottom'
                            },
                            tooltips: {
                                enabled: true,
                                callbacks: {
                                    label: function(tooltipItem, data) {
                                        if (tooltipItem.index === 0) {
                                            return 'Ikke fakturert: ' + value.notInvoiced;
                                        } else if (tooltipItem.index === 1) {
                                            return 'Fakturert: '  + value.invoiced;
                                        }
                                    }
                                }
                            },
                            title: {
                                display: true,
                                text: 'Ansvar: ' + value.ansvar,
                                fontSize: 16
                            },
                        }
                    });
                });
            }, function (result) {
                alert('Klarte ikke laste rapporten. Prøv igjen og gi beskjed til din kontaktperson ' +
                    'i DevelopIT dersom problemet vedvarer.');
            });

        }

    }
})();
