(function() {
    'use strict';

    angular
        .module('projectManagementApp')
        .controller('TimesheetNotificationController', TimesheetNotificationController);

    TimesheetNotificationController.$inject = ['$scope', '$http', 'AlertService'];

    function TimesheetNotificationController ($scope, $http) {
        var vm = this;

        loadData();

        function loadData() {
            $http.get('/api/timesheet/notification').then(function (result) {
                vm.notifications = result.data;
            }, function (result) {
                console.log(result);
                alert('Klarte ikke laste manglende timer. Prøv igjen og gi beskjed til din kontaktperson ' +
                    'i DevelopIT dersom problemet vedvarer.');
            });
        }

    }
})();
