(function() {
    'use strict';

    angular
        .module('projectManagementApp')
        .controller('SendEmailController', SendEmailController);

    SendEmailController.$inject = ['$scope', '$http', 'AlertService'];

    function SendEmailController ($scope, $http, AlertService) {
        var vm = this;

        vm.sendEmail = function sendEmail() {
            $http.get('/api/sendEmail').then(function () {
                AlertService.success('Email has been sent!');
            }, function (result) {
                console.log(result);
                alert('Send email failed!');
            });
        }

    }
})();
