(function() {
    'use strict';

    angular
        .module('projectManagementApp')
        .controller('ProjectController', ProjectController);

    ProjectController.$inject = ['$scope', 'Principal', 'LoginService', '$state', '$timeout', 'AlertService', '$http', '$stateParams', 'NgMap', '$location', '$window', '$anchorScroll', '$rootScope'];

    function ProjectController ($scope, Principal, LoginService, $state, $timeout, AlertService, $http, $stateParams, NgMap, $location, $window, $anchorScroll, $rootScope) {
        var vm = this;
        vm.account = null;

        // Get tenant from root scope in order to determine if this is Bydrift tenant or not.
        vm.isTenantBydrift = $rootScope.tenant === 'BYDRIFT_AND_VA';

        $scope.hourDisabled = true;
        $scope.massDisabled = true;
        $scope.equipmentHourDisabled = true;

        $scope.startDate = moment();

        $scope.invoiceReport = {
            fromDate: moment('2018-01-01'),
            toDate: moment(),
            includeMass: false,
            ansvarTjeneste: true,
            includeProduct: false,
            includeInactiveEquipment: false,
            invoiceForExternal: false,
            invoiceableStatus: 'ALL'
        };

        vm.fontSizeHoursTable = 10;
        vm.fontSizeMassTable = 10;
        vm.fontSizeEquipmentTable = 10;

        vm.maxLimitMembers = 3;
        vm.minlimitMembers = 3;
        vm.maxLimitMass = 3;
        vm.maxLimitEquipment = 3;
        vm.minLimitEquipment = 3;

        vm.maxLimitInvoices = 3;
        vm.minLimitInvoices = 3;

        // Show all
        vm.showAllEquipment = false;
        vm.userHasNoEquipmentOnThisProject = false;

        $scope.sjaList = [];
        $scope.budgetList = [];

        vm.selectedYear = moment().format('YYYY');

        $scope.externalProducts = [
            {name: 'Gravemaskin 18 - 25 tonn', price: 1010, unit: 'Time'},
            {name: 'Gravemaskin 13 - 17 tonn', price: 950, unit: 'Time'},
            {name: 'Gravemaskin 7 - 12 Tonn', price: 850, unit: 'Time'},
            {name: 'Gravemaskin 5,5 Tonn', price: 740, unit: 'Time'},
            {name: 'Gravemaskin 3,5 Tonn', price: 730, unit: 'Time'},
            {name: 'Gravemaskin 1,5 - 3 Tonn', price: 730, unit: 'Time'},
            {name: 'Hjulmaskin', price: 950, unit: 'Time'},
            {name: 'Lastebil', price: 850, unit: 'Time'},
            {name: 'Formann', price: 610, unit: 'Time'},
            {name: 'Manuelt (håndmann)', price: 570, unit: 'Time'},
            {name: 'Singel 2 - 5', price: 225, unit: 'Tonn'},
            {name: 'Singel 4 - 8', price: 190, unit: 'Tonn'},
            {name: 'Singel 4 - 16', price: 145, unit: 'Tonn'},
            {name: 'Pukk 16 - 32', price: 130, unit: 'Tonn'},
            {name: 'Pukk 20 - 64', price: 115, unit: 'Tonn'},
            {name: 'Pukk 20 - 120', price: 110, unit: 'Tonn'},
            {name: 'Steinstøv 0 - 4', price: 100, unit: 'Tonn'},
            {name: 'Steinstøv 0 - 8', price: 115, unit: 'Tonn'},
            {name: 'Grus 0 - 11', price: 115, unit: 'Tonn'},
            {name: 'Grus 0 - 16', price: 110, unit: 'Tonn'},
            {name: 'Grus 0 - 18', price: 95, unit: 'Tonn'},
            {name: 'Grus 0 - 22', price: 110, unit: 'Tonn'},
            {name: 'Grus 0 - 32', price: 105, unit: 'Tonn'},
            {name: 'Maskin legging', price: 0, unit: 'Tonn'},
            {name: 'Håndlegging', price: 0, unit: 'Tonn'},
            {name: 'Masser til gjenbruk', price: 65, unit: 'Tonn'},
            {name: 'Masser til tipp', price: 70, unit: 'Tonn'},
            {name: 'Asfalt ', price: 95, unit: 'Tonn'},
            {name: 'Betong', price: 500, unit: 'Tonn'},
            {name: '75 mm PP Rør', price: 85, unit: 'm.'},
            {name: '110 mm PP Rør', price: 103, unit: 'm.'},
            {name: '125 mm PP Rør', price: 150, unit: 'm.'},
            {name: '160 mm PP Rør', price: 230, unit: 'm.'},
            {name: '200 mm PP Rør', price: 360, unit: 'm.'},
            {name: '75 mm PP Deler', price: 55, unit: 'Stk.'},
            {name: '110 mm PP Deler', price: 85, unit: 'Stk.'},
            {name: '125 mm PP Deler', price: 160, unit: 'Stk.'},
            {name: '160 mm PP Deler', price: 263, unit: 'Stk.'},
            {name: '200 mm PP Deler', price: 570, unit: 'Stk.'},
            {name: '75 x 75 mm PP Grenrør', price: 110, unit: 'Stk.'},
            {name: '110 x 75 mm PP Grenrør', price: 120, unit: 'Stk.'},
            {name: '110 x 110 mm PP Grenrør', price: 130, unit: 'Stk.'},
            {name: '125 x 110 mm PP Grenrør', price: 170, unit: 'Stk.'},
            {name: '125 x 125 mm PP Grenrør', price: 210, unit: 'Stk.'},
            {name: '160 x 110 mm PP Grenrør', price: 235, unit: 'Stk.'},
            {name: '160 x 125 mm PP Grenrør', price: 260, unit: 'Stk.'},
            {name: '160 x 160 mm PP Grenrør', price: 350, unit: 'Stk.'},
            {name: '200 x 110 mm PP Grenrør', price: 530, unit: 'Stk.'},
            {name: '200 x 125 mm PP Grenrør', price: 545, unit: 'Stk.'},
            {name: '200 x 160 mm PP Grenrør', price: 730, unit: 'Stk.'},
            {name: '200 x 200 mm PP Grenrør', price: 1280, unit: 'Stk.'},
            {name: '110 x 75 mm PP Reduksjon', price: 70, unit: 'Stk.'},
            {name: '125 x 110 mm PP Reduksjon', price: 120, unit: 'Stk.'},
            {name: '160 x 110 mm PP Reduksjon', price: 200, unit: 'Stk.'},
            {name: '160 x 125 mm PP Reduksjon', price: 410, unit: 'Stk.'},
            {name: '200 x 160 mm PP Reduksjon', price: 340, unit: 'Stk.'},
            {name: 'Overgang PP Muffe til Betong spiss - 110 mm', price: 310, unit: 'Stk.'},
            {name: 'Overgang PP Muffe til Betong spiss - 125 mm', price: 340, unit: 'Stk.'},
            {name: 'Overgang PP Muffe til Betong spiss - 160 mm', price: 530, unit: 'Stk.'},
            {name: 'Overgang PP Muffe til Betong spiss - 200 mm', price: 600, unit: 'Stk.'},
            {name: 'Overgang PP Spiss til Betong Muffe - 110 mm', price: 220, unit: 'Stk.'},
            {name: 'Overgang PP Spiss til Betong Muffe - 125 mm', price: 260, unit: 'Stk.'},
            {name: 'Overgang PP Spiss til Betong Muffe - 160 mm', price: 420, unit: 'Stk.'},
            {name: 'Overgang PP Spiss til Betong Muffe - 200 mm ', price: 780, unit: 'Stk.'},
            {name: '110 x 315 mm Husdreneringskum', price: 4150, unit: 'Stk.'},
            {name: '315 x 375 mm Teleskop med klikk', price: 770, unit: 'Stk.'},
            {name: '315 x 750 mm Teleskop med klikk', price: 1200, unit: 'Stk.'},
            {name: '315 mm Skjøterør', price: 1350, unit: 'Stk.'},
            {name: '315 mm Stigerør', price: 910, unit: 'm.'},
            {name: '315 mm Betong lokk', price: 750, unit: 'Stk.'},
            {name: '315 mm SJG Lokk med ramme', price: 3590, unit: 'Stk.'},
            {name: '110 x 200 mm PP Stake/spylegren', price: 760, unit: 'Stk.'},
            {name: '200 mm SJG Lokk med Ramme', price: 1910, unit: 'Stk.'},
            {name: '200 mm Betong Lokk ', price: 650, unit: 'Stk.'},
            {name: '32 mm PE Rør', price: 32, unit: 'm.'},
            {name: '32 mm Bakkekran', price: 2640, unit: 'Stk.'},
            {name: '910 - 1660 mm Teleskop', price: 750, unit: 'Stk.'},
            {name: '1250 - 2350 mm Teleskop', price: 850, unit: 'Stk.'},
            {name: 'UF 15 Lokk med ramme', price: 1750, unit: 'Stk.'},
            {name: '32 mm PRK Union', price: 350, unit: 'Stk.'}
        ];

        $scope.changeProductSelection = function() {
            $scope.newProduct.name = $scope.newProduct.selectedProduct.name;
            $scope.newProduct.unit = $scope.newProduct.selectedProduct.unit;
            $scope.newProduct.price = $scope.newProduct.selectedProduct.price;
        };

        // Keep track of which subprojects have been selected.
        vm.invoicing = {
            selectedSubProjects: []
        };

        // Function for adding or removing subprojects from array.
        vm.addOrRemoveFromSelectedSubProjects = function(subProjectCode) {
            var index = vm.invoicing.selectedSubProjects.indexOf(subProjectCode);
            if (index > -1) {
                vm.invoicing.selectedSubProjects.splice(index, 1);
            } else {
                vm.invoicing.selectedSubProjects.push(subProjectCode);
            }
        };

        getAccount();

        // Load project data from server
        loadData();

        function loadData() {
            $http.get('/api/projects/' + $stateParams.id).then(function (result) {
                $scope.project = result.data;
                $scope.project.selectedDate = moment();
                $scope.project.previewImages = [];
                $scope.project.filesToUpload = [];

                // Use different limit for "Verksted" project.
                /*
                if ($scope.project.name == 'Verksted') {
                    vm.maxLimitMembers = 10;
                    vm.minlimitMembers = 10;

                    vm.maxLimitEquipment = 10;
                    vm.minLimitEquipment = 10;
                }
                */

                // Get members and masses for dropdown.
                getAvailableMembers();
                getAvailableMasses();

                // Get equipment for dropdown.
                $scope.getAvailableEquipment();

                // Get all member hours
                Principal.hasAuthority('ROLE_ADMIN').then(function (isAdmin) {
                    // Only request this for admins
                    if (isAdmin) {
                        $http.get('/api/projects/' + $scope.project.id + '/overview').then(function (result) {
                            $scope.project.sumMemberHours = result.data.projectMembers;

                            $scope.project.allMassAmounts = result.data.projectMass;
                            $scope.project.allEquipmentHours = result.data.projectEquipment;
                        });
                    }
                });

                // The map needs to be set after project has been loaded.
                NgMap.getMap().then(function (map) {
                    var latString = $scope.project.gpsLocation.latitude;
                    var lngString = $scope.project.gpsLocation.longitude;

                    var position = new google.maps.LatLng(
                        parseFloat(latString),
                        parseFloat(lngString));

                    new google.maps.Marker({
                        position: position,
                        map: map,
                        title: $scope.project.name
                    });

                    map.setCenter(position);
                    map.setZoom(15);
                });
            }, function (result) {
                alert('Klarte ikke finne prosjekt med id ' + $stateParams.id
                    + '. Gi beskjed til din kontaktperson i DevelopIT dersom problemet vedvarer.');
            });
        }

        function getAccount() {
            Principal.identity().then(function(account) {
                vm.account = account;
                vm.isAuthenticated = Principal.isAuthenticated;
            });
        }

        function getAvailableMasses() {
            $http.get('/api/availableMasses').then(function (result) {
                $scope.availableMasses = result.data;
            });
        }

        function getAvailableMembers() {
            $http.get('/api/availableMembers').then(function (result) {
                $scope.availableMembers = result.data;
            });
        }

        $scope.getAllMemberHours = function(year) {
            vm.selectedYear = year;
            $http.get('/api/projects/' + $scope.project.id + '/overview?year=' + year).then(function (result) {
                $scope.project.sumMemberHours = result.data.projectMembers;
            });
        }

        $scope.getAllMassAmounts = function(year) {
            $http.get('/api/projects/' + $scope.project.id + '/overview?year=' + year).then(function (result) {
                $scope.project.allMassAmounts = result.data.projectMass;
            });
        }

        $scope.getAllEquipmentHours = function(year) {
            $http.get('/api/projects/' + $scope.project.id + '/overview?year=' + year).then(function (result) {
                $scope.project.allEquipmentHours = result.data.projectEquipment;
            });
        }

        $scope.sortOrder = function(item) {
            return moment(item.date, 'DD.MM.YYYY').toDate();
        };

        $scope.getAvailableEquipment = function() {
            $http.get('/api/availableEquipment')
                .then(function (result) {
                    $scope.allEquipment = result.data.allEquipment;
                    $scope.equipmentLastUsedByMember = result.data.equipmentLastUsedByMember;
                }, function(result) {
                    alert('Klarte ikke hente ut tilgjengelig utstyr. Gi beskjed til din kontaktperson i DevelopIT ' +
                        'dersom problemet vedvarer.');
                });
        };

        $scope.updateSelectedDate = function(project, newValue, oldValue) {
            // Make sure we do not call this on first load.
            if (!oldValue) {
                return
            }

            $http.get('/api/projects/' + project.id + "/" + project.selectedDate.format('YYYY-MM-DD')
                + "?showAllEquipment=" + vm.showAllEquipment)
                .then(function(result) {
                    project.members = result.data.members;
                    project.masses = result.data.masses;
                    project.equipment = result.data.equipment;

                    // In case user has no registered equipment on this project, we will revert to showing all equipment.
                    /*
                    if (project.equipment.length == 0 && !vm.showAllEquipment && !vm.userHasNoEquipmentOnThisProject) {
                        vm.userHasNoEquipmentOnThisProject = true;
                        vm.showAllEquipment = true;
                        $scope.updateSelectedDate(project);
                        $scope.getAvailableEquipment();
                    }
                    */
            });
        };

        $scope.scrollToAnchor = function(anchor) {
            // set the location.hash to the id of
            // the element you wish to scroll to.
            $location.hash(anchor);

            // call $anchorScroll()
            $anchorScroll();
        };

        /* === Functions and variables used by the image carousel and paginator ===================================== */

        $scope.initialImageCountSp = 1;

        $scope.isMobileScreen = function() {
            return $window.innerWidth < 768;
        };

        $scope.mobileMaxHeight = function() {
            return ($window.innerWidth / 1.333) * 0.8;
        };

        $scope.initCarouselSp = function() {
            $('.image-carousel').slick({
            lazyLoad: 'ondemand',
            dots: false,
            infinite: false,
            speed: 500,
            slidesToShow: 1,
            centerMode: false,
            variableWidth: false,
            adaptiveHeight: false,
            mobileFirst: true,
            arrows: false,
            touchThreshold: 10,
            focusOnSelect: false
            });
        };

        $scope.paginatorStep = 9;
        $scope.paginatorLowLimit = 0;
        $scope.paginatorHighLimit = $scope.paginatorStep;

        $scope.paginatorGoToFirst = function() {
            $scope.paginatorLowLimit = 0;
            $scope.paginatorHighLimit = $scope.paginatorStep;
        };

        $scope.paginatorNext = function() {
            if ($scope.paginatorLowLimit + $scope.paginatorStep <= $scope.project.images.length) {
                $scope.paginatorLowLimit += $scope.paginatorStep;
                $scope.paginatorHighLimit += $scope.paginatorStep;
            }
        };

        $scope.paginatorPrev = function() {
            if ($scope.paginatorLowLimit - $scope.paginatorStep >= 0) {
                $scope.paginatorLowLimit -= $scope.paginatorStep;
                $scope.paginatorHighLimit -= $scope.paginatorStep;
            }
        };

        $scope.paginatorGoToLast = function() {
        };

        $scope.carouselGoToFirst = function() {
            $('.image-carousel').slick('slickGoTo', 0, 'true');
        };

        $scope.carouselNext = function() {
            $('.image-carousel').slick('slickNext');
        };

        $scope.carouselPrev = function() {
            $('.image-carousel').slick('slickPrev');
        };

        $scope.carouselGoToLast = function() {
            $('.image-carousel').slick('slickGoTo', $scope.project.images.length-1, 'true');
        };

        /* === Functions for updating min and max limits on members, masses and equipment =========================== */

        $scope.updateMinAndMaxLimitMembers = function(project) {
            // When member list is filtered, the show/hide button should _not_ be visible.
            if ($scope.filterMemberString.length > 0) {
                vm.minlimitMembers = project.members.length;
                vm.maxLimitMembers = project.members.length;
            }

            // When member list is _not_ filtered, the show/hide button should be visible.
            if ($scope.filterMemberString.length === 0) {
                /*
                if ($scope.project.name == 'Verksted') {
                    vm.maxLimitMembers = 10;
                    vm.minlimitMembers = 10;
                } else {
                */
                vm.minlimitMembers = 3;
                vm.maxLimitMembers = 3;
                //}
            }
        };

        $scope.updateMinAndMaxLimitEquipment = function(project) {
            // When equipment list is filtered, the show/hide button should _not_ be visible.
            if ($scope.filterEquipmentString.length > 0) {
                vm.minLimitEquipment = project.equipment.length;
                vm.maxLimitEquipment = project.equipment.length;
            }

            // When equipment list is _not_ filtered, the show/hide button should be visible.
            if ($scope.filterEquipmentString.length === 0) {
                /*
                if ($scope.project.name == 'Verksted') {
                    vm.maxLimitEquipment = 10;
                    vm.minLimitEquipment = 10;
                } else { */
                vm.minLimitEquipment = 3;
                vm.maxLimitEquipment = 3;
                //}
            }
        };

        /* === Functions for calculation of member hours, masses and equipment hours ================================ */

        $scope.calculateSumOfMemberHours = function(project) {
            var sum = 0;
            if (project != null) {
                angular.forEach(project.members, function(value, key){
                    sum += value.amount;
                });
            }
            return sum;
        }

        $scope.calculateSumOfMassAmounts = function(project) {
            var sum = 0;
            if (project != null) {
                angular.forEach(project.masses, function(value, key){
                    sum += value.amount;
                });
            }
            return sum;
        }

        $scope.calculateSumOfEquipmentHours = function(project) {
            var sum = 0;
            if (project != null) {
                angular.forEach(project.equipment, function(value, key){
                    sum += value.amount;
                });
            }
            return sum;
        }

        /* === Functions for adding member hours, masses and equipment hours ======================================== */

        $scope.addCurrentMemberToProject = function(project) {
            var member = null;
            angular.forEach($scope.availableMembers, function(value, key) {
                if (value.userId === vm.account.id) {
                    member = value;
                }
            });

            $scope.addMember(project, member);
        };

        $scope.addMember = function(project, member) {
            var found = false;
            angular.forEach(project.members, function(value, key) {
                if (value.id === member.id) {
                    found = true;
                }
            });

            // Member already part of project, nothing to be done
            if (!found) {
                $http({
                    method: 'POST',
                    url: 'api/projects/' + project.id + '/members',
                    data: {
                        memberId: member.id,
                        date: project.selectedDate.format('YYYY-MM-DD'),
                        hours: 0,
                        //TODO: Implement proper member cost
                        unitCost: 500
                    }
                }).then(function (response) {
                    response.data.status = 'NEW';
                    project.members.push(response.data);
                    project.projectMemberList.push(member.name);

                    // Get a fresh copy of member, mass and equipment hours for overview table
                    Principal.hasAuthority('ROLE_ADMIN').then(function (isAdmin) {
                        // Only request this for admins
                        if (isAdmin) {
                            $http.get('/api/projects/' + project.id + '/overview').then(function (result) {
                                $scope.project.allMemberHours = result.data.projectMember;
                                $scope.project.allMassAmounts = result.data.projectMass;
                                $scope.project.allEquipmentHours = result.data.projectEquipment;
                            });
                        }
                    });
                }, function (response) {
                    alert("En feil oppstod. Sjekk loggen!");
                    console.log(response);
                });
            }
        };

        $scope.addMass = function(project, mass) {
            var found = false;

            // Check if mass is already part of project.
            angular.forEach(project.masses, function(value, key) {
                // NOTE: Need to use == and not === here because we need to be able to compare int with string
                if (value.id == mass.id) {
                    found = true;
                }
            });

            // If not found, proceed with add.
            if (!found) {
                $http({
                    method: 'POST',
                    url: 'api/projects/' + project.id + '/masses',
                    data: {
                        massId: mass.id,
                        date: project.selectedDate.format('YYYY-MM-DD'),
                        amount: 0,
                        unitCost: mass.price
                    }
                }).then(function(response) {
                    // TODO: Or get this from server instead?
                    project.masses.push({
                        id: mass.id,
                        name: mass.type,
                        unit: mass.unit,
                        amount: 0,
                        options: mass.options
                    });

                    // Get a fresh copy of member, mass and equipment hours for overview table
                    Principal.hasAuthority('ROLE_ADMIN').then(function (isAdmin) {
                        // Only request this for admins
                        if (isAdmin) {
                            $http.get('/api/projects/' + project.id + '/overview').then(function (result) {
                                $scope.project.allMemberHours = result.data.projectMember;
                                $scope.project.allMassAmounts = result.data.projectMass;
                                $scope.project.allEquipmentHours = result.data.projectEquipment;
                            });
                        }
                    });
                }, function(response) {
                    alert("En feil oppstod. Sjekk loggen!");
                    console.log(response);
                });
            }
        };

        $scope.addEquipment = function(project, equipment) {
            var found = false;
            angular.forEach(project.equipment, function(value, key) {
                // NOTE: Need to use == and not === here because we need to be able to compare int with string
                if (value.id == equipment.id) {
                    found = true;
                }
            });

            if (!found) {
                $http({
                    method: 'POST',
                    url: 'api/projects/' + project.id + '/equipment',
                    data: {
                        equipmentId: equipment.id,
                        date: project.selectedDate.format('YYYY-MM-DD'),
                        hours: 0,
                        unitCost: equipment.price
                    }
                }).then(function(response) {
                    // TODO: Or get this from server instead?
                    project.equipment.push({
                        id: equipment.id,
                        name: equipment.label,
                        amount: 0,
                        sortOrder: 0,
                        status: 'NEW'
                    });

                    // Get a fresh copy of member, mass and equipment hours for overview table
                    Principal.hasAuthority('ROLE_ADMIN').then(function (isAdmin) {
                        // Only request this for admins
                        if (isAdmin) {
                            $http.get('/api/projects/' + project.id + '/overview').then(function (result) {
                                $scope.project.allMemberHours = result.data.projectMember;
                                $scope.project.allMassAmounts = result.data.projectMass;
                                $scope.project.allEquipmentHours = result.data.projectEquipment;
                            });
                        }
                    });
                }, function(response) {
                    alert("En feil oppstod. Sjekk loggen!");
                    console.log(response);
                });
            }

        };

        /* === Functions for saving member hours, masses and equipment hours ======================================== */

        // Used for setting tag on either member or equipment.
        $scope.updateTagOnMemberOrEquipmentHours = function(memberOrEquipment, tag) {
            if (memberOrEquipment.isForsand) {
                memberOrEquipment.tag = tag;
            } else {
                memberOrEquipment.tag = null;
            }
        };

        $scope.saveHours = function(project) {
            $http({
                method: 'POST',
                url: 'api/projects/' + project.id + '/hours',
                data: {
                    date: project.selectedDate.format('YYYY-MM-DD'),
                    members: project.members
                }
            }).then(function(response) {
                project.summary = response.data.summary;
                AlertService.success("Timer er registrert");

                // Get a fresh copy of member, mass and equipment hours for overview table
                Principal.hasAuthority('ROLE_ADMIN').then(function (isAdmin) {
                    // Only request this for admins
                    if (isAdmin) {
                        $http.get('/api/projects/' + project.id + '/overview').then(function (result) {
                            $scope.project.allMemberHours = result.data.projectMember;
                            $scope.project.allMassAmounts = result.data.projectMass;
                            $scope.project.allEquipmentHours = result.data.projectEquipment;
                        });
                    }
                });
            }, function(response) {
                alert("En feil oppstod. Sjekk loggen!");
                console.log(response);
            });
        };

        $scope.saveMasses = function(project) {
            $http({
                method: 'POST',
                url: 'api/projects/' + project.id + '/masses/amount',
                data: {
                    date: project.selectedDate.format('YYYY-MM-DD'),
                    massesAmount: project.masses
                }
            }).then(function(response) {
                project.summary = response.data.summary;
                AlertService.success("Masse er registrert");

                // Disable masses again.
                $scope.toggleDisabledForList(project.masses);

                // Get a fresh copy of member, mass and equipment hours for overview table
                Principal.hasAuthority('ROLE_ADMIN').then(function (isAdmin) {
                    // Only request this for admins
                    if (isAdmin) {
                        $http.get('/api/projects/' + project.id + '/overview').then(function (result) {
                            $scope.project.allMemberHours = result.data.projectMember;
                            $scope.project.allMassAmounts = result.data.projectMass;
                            $scope.project.allEquipmentHours = result.data.projectEquipment;
                        });
                    }
                });
            }, function(response) {
                alert("En feil oppstod. Sjekk loggen!");
                console.log(response);
            });
        };

        /* Update product if it exists, create if not. */
        $scope.saveProduct = function() {
            var project = $scope.newProduct.project;
            $http({
                method: 'POST',
                url: 'api/projects/' + $scope.newProduct.project.id + '/product',
                data: {
                    id: $scope.newProduct.id,
                    type: $scope.newProduct.type,
                    name: $scope.newProduct.name,
                    date: $scope.newProduct.date,
                    momentDate: $scope.newProduct.momentDate.format('YYYY-MM-DD'),
                    quantity: $scope.newProduct.quantity,
                    price: $scope.newProduct.price
                }
            }).then(function(response) {
                project.products = response.data.products;
                project.summary = response.data.summary;
                if ($scope.newProduct.id) {
                    AlertService.success("Produkt er oppdatert");
                } else {
                    AlertService.success("Produkt er registrert");
                }
            }, function(response) {
                alert("En feil oppstod. Sjekk loggen!");
                console.log(response);
            });
            $('#newProductModal').modal('hide');
        };

        $scope.saveEquipment = function(project) {
            $http({
                method: 'POST',
                url: 'api/projects/' + project.id + '/equipment/hours',
                data: {
                    date: project.selectedDate.format('YYYY-MM-DD'),
                    equipmentHours: project.equipment
                }
            }).then(function(response) {
                project.summary = response.data.summary;
                AlertService.success("Timer er registert på utstyr");

                // Get a fresh copy of member, mass and equipment hours for overview table
                Principal.hasAuthority('ROLE_ADMIN').then(function (isAdmin) {
                    // Only request this for admins
                    if (isAdmin) {
                        $http.get('/api/projects/' + project.id + '/overview').then(function (result) {
                            $scope.project.allMemberHours = result.data.projectMember;
                            $scope.project.allMassAmounts = result.data.projectMass;
                            $scope.project.allEquipmentHours = result.data.projectEquipment;
                        });
                    }
                });
            }, function(response) {
                alert("En feil oppstod. Sjekk loggen!");
                console.log(response);
            });
        };

        //Save the updated description of the images
        $scope.saveImageDescription = function(project) {
            var formData = new FormData();
            formData.append('imageId', project.previewImage.id)
            formData.append('comment', project.previewImage.comment);
            formData.append('projectId', project.id);
            $http.post('/api/uploadImage', formData, {
                headers: {'Content-Type': undefined}
            }).then(function(result) {
                var i;
                for (i = 0; i < project.images.length; i++) {
                    if (project.previewImage.id == project.images[i].id) {
                        project.images[i].description = project.previewImage.comment;
                    }
                }
                project.previewImage = {};
                AlertService.success("Bildebeskrivelse er oppdatert.");
                $('#editImageModal').modal('hide');
            })
        }

        $scope.saveImages  = function(project) {
            // Upload all images
            var i;
            for (i = 0; i < project.previewImages.length; i++) {
                var formData = new FormData();
                formData.append('file', project.filesToUpload[i]);
                formData.append('comment', project.previewImages[i].comment);
                formData.append('projectId', project.id);
                $http.post('/api/uploadImage', formData, {
                    headers: {'Content-Type': undefined}
                }).then(function(result) {
                    if ($('.image-carousel')) {
                        $('.image-carousel').slick('unslick');
                    }
                    project.images = result.data.images;
                    project.filesToUpload = [];
                    project.previewImages = [];
                    AlertService.success("Bilder er lastet opp");
                    $('#addImagesModal').modal('hide');
                })
            }
        };

        $scope.updateProject = function() {
            var project = $scope.editProject;
            $http({
                method: 'PATCH',
                url: 'api/projects',
                data: {
                    id: project.id,
                    name: project.name,
                    projectNo: project.projectNo,
                    type: project.type,
                    department: project.department,
                    address: project.address,
                    postCode: project.postCode,
                    postLocation: project.postLocation,
                    description: project.description,
                    gpsLocation: {
                        latitude: project.gpsLocation.latitude,
                        longitude: project.gpsLocation.longitude
                    },
                    status: project.status,
                    principal: project.principal,
                    principalDepartment: project.principalDepartment,
                    contactPerson: project.contactPerson,
                    principalArt: project.principalArt,
                    principalTjeneste: project.principalTjeneste,
                    principalAnsvar: project.principalAnsvar,
                    invoiceType: project.invoiceType,
                    fixedPrice: project.fixedPrice,
                    notificationEmail: project.notificationEmail
                }
            }).then(function(response) {
                AlertService.success("Prosjektet er oppdatert!");
                $('#editProjectModal').modal('hide');
            }, function(response) {
                alert("En feil oppstod. Sjekk loggen!");
                console.log(response);
            });
        };

        $scope.onDocumentSelected = function(event) {
            $scope.newDocument.file = event.target.files[0];
        };

        $scope.saveDocument = function() {
            var project = $scope.newDocument.project;
            var formData = new FormData();
            if (!isNaN($scope.newDocument.id)) {
                formData.append('fileId', $scope.newDocument.id);
            } else {
                formData.append('file', $scope.newDocument.file);
            }

            formData.append('title', $scope.newDocument.name);
            formData.append('comment', $scope.newDocument.description);
            formData.append('projectId', project.id);
            $http.post('/api/uploadDocument', formData, {
                headers: {'Content-Type': undefined}
            }).then(function(result) {
                $('#newDocumentModal').modal('hide');
                if ($scope.newDocument.id) {
                    AlertService.success("Dokumentbeskrivelse oppdatert");
                } else {
                    AlertService.success("Dokumentet er lastet opp");
                }
                project.documents = result.data.documents;
            }, function(result) {
                $('#newDocumentModal').modal('hide');
                AlertService.error("En feil oppstod. Dokumentet er ikke lastet opp.");
                console.log('An error occurred when uploading document');
                console.log(result);
            });
            document.getElementById("inputDocumentFile").value = "";
        };

           /* Use REST-endpoint to save project log. Does update if id already exists */
        $scope.saveProjectLog = function () {
            var project = $scope.newProjectLog.project;
            var formData = new FormData();
            if ($scope.newProjectLog.filesToUpload.length > 0) {
                formData.append('file', $scope.newProjectLog.filesToUpload[0]);
            }
            if (!isNaN($scope.newProjectLog.id)) {
                formData.append('logId', $scope.newProjectLog.id);
            }
            formData.append('comment', $scope.newProjectLog.comment);
            formData.append('projectId', project.id);
            formData.append('logNo', $scope.newProjectLog.logNo);
            formData.append('dateAndTime', $scope.newProjectLog.dateAndTime.format('YYYY-MM-DD HH:mm'));
            formData.append('responsible', $scope.newProjectLog.responsible);
            formData.append('whatHasChanged', $scope.newProjectLog.whatHasChanged);
            formData.append('type', $scope.newProjectLog.type);
            $http.post('/api/uploadImageWithLog', formData, {
                headers: {'Content-Type': undefined}
            }).then(function(result) {
                project.logs = result.data.logs;
                $('#newLogModal').modal('hide');
                if ($scope.newProjectLog.id) {
                    AlertService.success("Loggbok oppdatert");
                } else {
                    AlertService.success("Ny oppføring i loggboken er lagret");
                }
            }, function(result) {
                $('#newLogModal').modal('hide');
                AlertService.error("En feil oppstod. Loggen er ikke oppdatert!");
                console.log('An error occurred when uploading project log');
                console.log(result);
            });
        };

        /* Use REST-endpoint to save project log. Does update if id already exists */
        $scope.saveWorkLog = function () {
            var project = $scope.newWorkLog.project;
            var formData = new FormData();
            if ($scope.newWorkLog.filesToUpload.length > 0) {
                formData.append('file', $scope.newWorkLog.filesToUpload[0]);
            }
            if (!isNaN($scope.newWorkLog.id)) {
                formData.append('logId', $scope.newWorkLog.id);
            }
            formData.append('comment', $scope.newWorkLog.comment);
            formData.append('projectId', project.id);
            formData.append('dateAndTime', $scope.newWorkLog.dateAndTime.format('YYYY-MM-DD HH:mm'));
            formData.append('type', $scope.newWorkLog.type);
            formData.append('logType', $scope.newWorkLog.logType);
            formData.append('address', $scope.newWorkLog.address);
            formData.append('responsible', $scope.newWorkLog.responsible);
            $http.post('/api/uploadImageWithLog', formData, {
                headers: {'Content-Type': undefined}
            }).then(function(result) {
                project.workLogs = result.data.workLogs;
                $('#newWorkLogModal').modal('hide');
                if ($scope.newWorkLog.id) {
                    AlertService.success("Loggbok for arbeidsvarsling oppdatert");
                } else {
                    AlertService.success("Ny loggbok for arbeidsvarsling lagret");
                }
            }, function(result) {
                $('#newWorkLogModal').modal('hide');
                AlertService.error("En feil oppstod. Loggen er ikke oppdatert!");
                console.log('An error occurred when uploading project log');
                console.log(result);
            });
        };

        /* Can probably be removed.
        function clearProjectInput() {
            $scope.newProject = {
                projectMemberList: [],
                summary: {
                    memberHours: 0,
                    equipmentHours: 0,
                    estimatedCost: 0,
                    massCost: 0
                },
                members: [],
                masses: [],
                equipment: [],
                documents: [],
                images: []
            };
        }
         */

        $scope.addImages = function(event) {
            var files = event.target.files;
            var project = $scope.project;

            var i;
            for (i = 0; i < files.length; i++) {

                // Finding orientation of image
                var orientation = null;
                EXIF.getData(files[i], function() {
                    orientation = EXIF.getTag(this, "Orientation");
                });

                var fileReader = new FileReader();

                // Resize image
                fileReader.onload = function (readerEvent) {
                    var image = new Image();
                    image.onload = function (imageEvent) {

                        // Resize the image
                        var canvas = document.createElement('canvas');
                        var max_size = 1200; // TODO : pull max size from a site config

                        var width, height;
                        if (orientation === 8 || orientation === 6) {
                            // For orientation 8 and 6 we need to flip the width and height
                            width = image.height;
                            height = image.width;
                        } else {
                            width = image.width;
                            height = image.height;
                        }

                        // Resize according to max_size
                        if (width > height) {
                            if (width > max_size) {
                                height *= max_size / width;
                                width = max_size;
                            }
                        } else {
                            if (height > max_size) {
                                width *= max_size / height;
                                height = max_size;
                            }
                        }

                        canvas.width = width;
                        canvas.height = height;

                        var context = canvas.getContext('2d');
                        context.imageSmoothingEnabled = true;

                        if (orientation === 8 || orientation === 6 || orientation === 3) {
                            // move to the center of the canvas
                            context.translate(canvas.width / 2, canvas.height / 2);

                            if (orientation === 8) {
                                context.rotate(-90 * Math.PI / 180);
                            } else if (orientation === 6) {
                                context.rotate(90 * Math.PI / 180);
                            } else {
                                context.rotate(180 * Math.PI / 180);
                            }
                        }

                        // Draw the image
                        if (orientation === 8 || orientation === 6) {
                            context.drawImage(image, -canvas.height / 2, -canvas.width / 2, height, width);
                        } else if (orientation === 3) {
                            context.drawImage(image, -canvas.width / 2, -canvas.height / 2, width, height);
                        } else {
                            context.drawImage(image, 0, 0, width, height)
                        }

                        var dataUrl = canvas.toDataURL('image/jpeg');
                        var resizedImage = dataURLToBlob(dataUrl);
                        project.filesToUpload.push(resizedImage);

                        project.previewImages.push({
                            imageUrl: dataUrl,
                            comment: ''
                        });
                        $scope.$apply();
                    };
                    image.src = readerEvent.target.result;
                };
                fileReader.readAsDataURL(files[i]);
            }

            $scope.$apply();
        };

        // XXX: Refactor me please!!!! A lot of copy and paste from addImages()
        $scope.addImageToProjectLog = function(event) {
            var files = event.target.files;
            var project = $scope.project;

            var i;
            for (i = 0; i < files.length; i++) {

                // Finding orientation of image
                var orientation = null;
                EXIF.getData(files[i], function() {
                    orientation = EXIF.getTag(this, "Orientation");
                });

                var fileReader = new FileReader();

                // Resize image
                fileReader.onload = function (readerEvent) {
                    var image = new Image();
                    image.onload = function (imageEvent) {

                        // Resize the image
                        var canvas = document.createElement('canvas');
                        var max_size = 1200; // TODO : pull max size from a site config

                        var width, height;
                        if (orientation === 8 || orientation === 6) {
                            // For orientation 8 and 6 we need to flip the width and height
                            width = image.height;
                            height = image.width;
                        } else {
                            width = image.width;
                            height = image.height;
                        }

                        // Resize according to max_size
                        if (width > height) {
                            if (width > max_size) {
                                height *= max_size / width;
                                width = max_size;
                            }
                        } else {
                            if (height > max_size) {
                                width *= max_size / height;
                                height = max_size;
                            }
                        }

                        canvas.width = width;
                        canvas.height = height;

                        var context = canvas.getContext('2d');
                        context.imageSmoothingEnabled = true;

                        if (orientation === 8 || orientation === 6 || orientation === 3) {
                            // move to the center of the canvas
                            context.translate(canvas.width / 2, canvas.height / 2);

                            if (orientation === 8) {
                                context.rotate(-90 * Math.PI / 180);
                            } else if (orientation === 6) {
                                context.rotate(90 * Math.PI / 180);
                            } else {
                                context.rotate(180 * Math.PI / 180);
                            }
                        }

                        // Draw the image
                        if (orientation === 8 || orientation === 6) {
                            context.drawImage(image, -canvas.height / 2, -canvas.width / 2, height, width);
                        } else if (orientation === 3) {
                            context.drawImage(image, -canvas.width / 2, -canvas.height / 2, width, height);
                        } else {
                            context.drawImage(image, 0, 0, width, height)
                        }

                        var dataUrl = canvas.toDataURL('image/jpeg');
                        var resizedImage = dataURLToBlob(dataUrl);
                        $scope.newProjectLog.filesToUpload.push(resizedImage);
                    };
                    image.src = readerEvent.target.result;
                };
                fileReader.readAsDataURL(files[i]);
            }
        };

        // XXX: Refactor me please!!!! A lot of copy and paste from addImages()
        $scope.addImageToWorkLog = function(event) {
            var files = event.target.files;
            var project = $scope.project;

            var i;
            for (i = 0; i < files.length; i++) {

                // Finding orientation of image
                var orientation = null;
                EXIF.getData(files[i], function() {
                    orientation = EXIF.getTag(this, "Orientation");
                });

                var fileReader = new FileReader();

                // Resize image
                fileReader.onload = function (readerEvent) {
                    var image = new Image();
                    image.onload = function (imageEvent) {

                        // Resize the image
                        var canvas = document.createElement('canvas');
                        var max_size = 1200; // TODO : pull max size from a site config

                        var width, height;
                        if (orientation === 8 || orientation === 6) {
                            // For orientation 8 and 6 we need to flip the width and height
                            width = image.height;
                            height = image.width;
                        } else {
                            width = image.width;
                            height = image.height;
                        }

                        // Resize according to max_size
                        if (width > height) {
                            if (width > max_size) {
                                height *= max_size / width;
                                width = max_size;
                            }
                        } else {
                            if (height > max_size) {
                                width *= max_size / height;
                                height = max_size;
                            }
                        }

                        canvas.width = width;
                        canvas.height = height;

                        var context = canvas.getContext('2d');
                        context.imageSmoothingEnabled = true;

                        if (orientation === 8 || orientation === 6 || orientation === 3) {
                            // move to the center of the canvas
                            context.translate(canvas.width / 2, canvas.height / 2);

                            if (orientation === 8) {
                                context.rotate(-90 * Math.PI / 180);
                            } else if (orientation === 6) {
                                context.rotate(90 * Math.PI / 180);
                            } else {
                                context.rotate(180 * Math.PI / 180);
                            }
                        }

                        // Draw the image
                        if (orientation === 8 || orientation === 6) {
                            context.drawImage(image, -canvas.height / 2, -canvas.width / 2, height, width);
                        } else if (orientation === 3) {
                            context.drawImage(image, -canvas.width / 2, -canvas.height / 2, width, height);
                        } else {
                            context.drawImage(image, 0, 0, width, height)
                        }

                        var dataUrl = canvas.toDataURL('image/jpeg');
                        var resizedImage = dataURLToBlob(dataUrl);
                        $scope.newWorkLog.filesToUpload.push(resizedImage);
                    };
                    image.src = readerEvent.target.result;
                };
                fileReader.readAsDataURL(files[i]);
            }
        };

        /* Utility function to convert a canvas to a BLOB */
        var dataURLToBlob = function(dataURL) {
            var BASE64_MARKER = ';base64,';
            if (dataURL.indexOf(BASE64_MARKER) == -1) {
                var parts = dataURL.split(',');
                var contentType = parts[0].split(':')[1];
                var raw = parts[1];

                return new Blob([raw], {type: contentType});
            }

            var parts = dataURL.split(BASE64_MARKER);
            var contentType = parts[0].split(':')[1];
            var raw = window.atob(parts[1]);
            var rawLength = raw.length;

            var uInt8Array = new Uint8Array(rawLength);

            for (var i = 0; i < rawLength; ++i) {
                uInt8Array[i] = raw.charCodeAt(i);
            }

            return new Blob([uInt8Array], {type: contentType});
        };
        /* End Utility function to convert a canvas to a BLOB      */

        var isPortrait = function(img) {
            var w = img.naturalWidth || img.width,
                h = img.naturalHeight || img.height;
            return (h > w);
        }

        $scope.setGpsLocation = function(project) {
            if (navigator.geolocation) {
                navigator.geolocation.getCurrentPosition(function(position) {
                    if (!project) {
                        $scope.$apply(function(){
                            $scope.editProject.gpsLocation = {
                                latitude: position.coords.latitude,
                                longitude: position.coords.longitude
                            }
                        });
                    } else {
                        $scope.$apply(function(){
                            project.gpsLocation.latitude = position.coords.latitude;
                            project.gpsLocation.longitude = position.coords.longitude;
                        });
                    }
                }, function showError(error) {
                    alert("Ikke mulig å hente lokasjonen din nå (" + error + ")");
                });
            }
        };

        $scope.setGpsLocationBasedOnAddress = function() {
            if ($scope.editProject.address && $scope.editProject.postLocation) {
                var geocoder = new google.maps.Geocoder();
                geocoder.geocode({
                    'address': $scope.editProject.address + ', ' + $scope.editProject.postLocation + ', Norway'
                }, function(results, status) {
                    if (status === google.maps.GeocoderStatus.OK) {
                        if (status !== google.maps.GeocoderStatus.ZERO_RESULTS) {
                            $scope.$apply(function(){
                                $scope.editProject.gpsLocation = {
                                    latitude: results[0].geometry.location.lat(),
                                    longitude: results[0].geometry.location.lng()
                                }
                            });
                        } else {
                            alert("No results found");
                        }
                    } else {
                        alert("Geocode was not successful for the following reason: " + status);
                    }
                });
            }
        };

         /* === DELETE AND REMOVE ==================================================================================== */

        $scope.removeMemberFromProject = function () {
            var project = $scope.memberToDelete.project;
            $http({
                method: 'DELETE',
                url: 'api/projects/' + project.id + '/members/' + $scope.memberToDelete.memberId,
                data: null
            }).then(function(response) {
                project.selectedDate = moment();
                project.summary = response.data.summary;
                project.members = response.data.members;

                // Get a fresh copy of member, mass and equipment hours for overview table
                Principal.hasAuthority('ROLE_ADMIN').then(function (isAdmin) {
                    // Only request this for admins
                    if (isAdmin) {
                        $http.get('/api/projects/' + project.id + '/overview').then(function (result) {
                            $scope.project.allMemberHours = result.data.projectMember;
                            $scope.project.allMassAmounts = result.data.projectMass;
                            $scope.project.allEquipmentHours = result.data.projectEquipment;
                        });
                    }
                });

                $('#confirmDeleteMemberModal').modal('hide');
            }, function(response) {
                alert("En feil oppstod. Sjekk loggen!");
                console.log(response);
                $('#confirmDeleteMemberModal').modal('hide');
            });
        };

        $scope.removeMassFromProject = function() {
            var project = $scope.massToDelete.project;
            $http({
                method: 'DELETE',
                url: 'api/projects/' + project.id + '/masses/' + $scope.massToDelete.massId,
                data: null
            }).then(function(response) {
                project.selectedDate = moment();
                project.summary = response.data.summary;
                project.masses = response.data.masses;

                // Get a fresh copy of member, mass and equipment hours for overview table
                Principal.hasAuthority('ROLE_ADMIN').then(function (isAdmin) {
                    // Only request this for admins
                    if (isAdmin) {
                        $http.get('/api/projects/' + project.id + '/overview').then(function (result) {
                            $scope.project.allMemberHours = result.data.projectMember;
                            $scope.project.allMassAmounts = result.data.projectMass;
                            $scope.project.allEquipmentHours = result.data.projectEquipment;
                        });
                    }
                });

                $('#confirmDeleteMassModal').modal('hide');
                $scope.massDisabled = true;
            }, function(response) {
                alert("En feil oppstod. Sjekk loggen!");
                console.log(response);
                $('#confirmDeleteMassModal').modal('hide');
            });
        }

        $scope.removeEquipmentFromProject = function() {
            var project = $scope.equipmentToDelete.project;
            $http({
                method: 'DELETE',
                url: 'api/projects/' + project.id + '/equipment/' + $scope.equipmentToDelete.equipmentId,
                data: null
            }).then(function(response) {
                // TODO: Why reset the date???
                project.selectedDate = moment();
                project.summary = response.data.summary;
                project.equipment = response.data.equipment;

                // Get a fresh copy of member, mass and equipment hours for overview table
                Principal.hasAuthority('ROLE_ADMIN').then(function (isAdmin) {
                    // Only request this for admins
                    if (isAdmin) {
                        $http.get('/api/projects/' + project.id + '/overview').then(function (result) {
                            $scope.project.allMemberHours = result.data.projectMember;
                            $scope.project.allMassAmounts = result.data.projectMass;
                            $scope.project.allEquipmentHours = result.data.projectEquipment;
                        });
                    }
                });

                $('#confirmDeleteEquipmentModal').modal('hide');
                $scope.equipmentHourDisabled = true;

                // If no equipment for this user, we should show all equipment instead.
                /*
                if (project.equipment.length === 0) {
                    vm.userHasNoEquipmentOnThisProject = true;
                    vm.showAllEquipment = true;
                    $scope.updateSelectedDate(project);
                    $scope.getAvailableEquipment();
                }
                */

            }, function(response) {
                alert("En feil oppstod. Sjekk loggen!");
                console.log(response);
                $('#confirmDeleteEquipmentModal').modal('hide');
                $scope.equipmentHourDisabled = true;
            });
        };

        $scope.deleteProject = function () {
            var project = $scope.projectToDelete.project;
            $http({
                method: 'DELETE',
                url: 'api/projects/' + project.id,
                data: null
            }).then(function(response) {
                $('#confirmDeleteProjectModal').modal('hide');

                // Wait a little bit to make the modal window close properly first
                $timeout(function() {
                    $state.go('home');
                }, 250);
            }, function(response) {
                alert("En feil oppstod. Sjekk loggen!");
                console.log(response);
                $('#confirmDeleteProjectModal').modal('hide');
            });
        };

        /* Delete product using REST endpoint. Project will be updated on successful response */
        $scope.deleteProduct = function () {
            var project = $scope.productToDelete.project;
            var product = $scope.productToDelete.product;
            $http({
                method: 'DELETE',
                url: 'api/projects/' + project.id + '/products/' + product.id,
                data: null
            }).then(function(response) {
                project.products = response.data.products;
                project.summary = response.data.summary;
                $('#confirmDeleteProductModal').modal('hide');
            }, function(response) {
                alert("En feil oppstod. Sjekk loggen!");
                console.log(response);
                $('#confirmDeleteProductModal').modal('hide');
            });
        };

        /* Delete document/invoice using REST endpoint */
        $scope.deleteDocument = function (isInvoice) {
            var project = $scope.documentToDelete.project;
            var document = $scope.documentToDelete.document;
            var path = isInvoice ? 'invoices' : 'documents';
            $http({
                method: 'DELETE',
                url: 'api/projects/' + project.id + '/' + path + '/' + document.id,
                data: null
            }).then(function(response) {
                project.documents = response.data.documents;
                project.invoices = response.data.invoices;
                project.products = response.data.products;
                $('#confirmDeleteDocumentModal').modal('hide');

                // Do a reload of data for this project.
                loadData();
            }, function(response) {
                alert("En feil oppstod. Sjekk loggen!");
                console.log(response);
                $('#confirmDeleteDocumentModal').modal('hide');
            });
        };

        /* Delete preview image from project */
        $scope.deletePreviewImage = function (project, image) {
            var index = project.previewImages.indexOf(image);
            project.previewImages.splice(index, 1);
            // TODO: Need to verify that this will remove correct image
            project.filesToUpload.splice(index, 1);
        };

        /* Delete image using REST endpoint */
        $scope.deleteImage = function () {
            var project = $scope.imageToDelete.project;
            var image = $scope.imageToDelete.image;
            $http({
                method: 'DELETE',
                url: 'api/projects/' + project.id + '/images/' + image.id,
                data: null
            }).then(function(response) {
                if ($('.image-carousel')) {
                    $('.image-carousel').slick('unslick');
                }
                project.images = response.data.images;
                $('#confirmDeleteImageModal').modal('hide');
            }, function(response) {
                alert("En feil oppstod. Sjekk loggen!");
                console.log(response);
                $('#confirmDeleteImageModal').modal('hide');
            });
        };

        /* Delete log using REST endpoint */
        $scope.deleteLog = function () {
            var project = $scope.logToDelete.project;
            var log = $scope.logToDelete.log;
            $http({
                method: 'DELETE',
                url: 'api/projects/' + project.id + '/logs/' + log.id,
                data: null
            }).then(function(response) {
                project.logs = response.data.logs;
                $('#confirmDeleteLogModal').modal('hide');
            }, function(response) {
                alert("En feil oppstod. Sjekk loggen!");
                console.log(response);
                $('#confirmDeleteLogModal').modal('hide');
            });
        };

        /* Delete work log using REST endpoint */
        $scope.deleteWorkLog = function () {
            var project = $scope.workLogToDelete.project;
            var log = $scope.workLogToDelete.log;
            $http({
                method: 'DELETE',
                url: 'api/projects/' + project.id + '/logs/' + log.id,
                data: null
            }).then(function(response) {
                project.workLogs = response.data.workLogs;
                $('#confirmDeleteWorkLogModal').modal('hide');
            }, function(response) {
                alert("En feil oppstod. Sjekk loggen!");
                console.log(response);
                $('#confirmDeleteWorkLogModal').modal('hide');
            });
        };

        /* Invoke endpoint for exporting project */
        $scope.createInvoiceReport = function (preview) {
            var url = null;
            var project = $scope.project;
            var from = $scope.invoiceReport.fromDate.format('YYYY-MM-DD');
            var to = $scope.invoiceReport.toDate.format('YYYY-MM-DD');
            var win = $window.open();
            $http({
                method: 'GET',
                url: 'api/invoice/' + from + '/' + to + '/' + project.id
                    + '?includeMass=' + $scope.invoiceReport.includeMass
                    + '&reportOnAnsvarAndTjeneste=' + $scope.invoiceReport.ansvarTjeneste
                    + '&preview=' + preview
                    + '&includeProduct=' + $scope.invoiceReport.includeProduct
                    + '&includeInactiveEquipment=' + $scope.invoiceReport.includeInactiveEquipment
                    + '&invoiceForExternal=' + $scope.invoiceReport.invoiceForExternal
                    + '&invoiceableStatus=' + $scope.invoiceReport.invoiceableStatus
                    + '&subProjectCodes=' + vm.invoicing.selectedSubProjects,
                data: null
            }).then(function(response) {
                $('#confirmCreateInvoiceModal').modal('hide');
                if (!preview) {
                    // Add invoice to project
                    $scope.project.invoices.push(response.data);
                    // Clear selected subprojects.
                    vm.invoicing.selectedSubProjects = [];
                    // Also, do a full refresh of project since creating an invoice should lock products/hours.
                    loadData();
                }
                url = response.data.url;
                win.location = url;
            }, function (response)  {
                $('#confirmCreateInvoiceModal').modal('hide');
                alert("En feil oppstod. Sjekk loggen!");
                console.log(response);
            });
        };

        /* === MODAL ================================================================================================ */

        // TODO: Can this be removed???
        /*
        $scope.showAddProjectModal = function() {
            $scope.newProject = {
                projectMemberList: [],
                summary: {
                    memberHours: 0,
                    equipmentHours: 0,
                    estimatedCost: 0,
                    massCost: 0
                },
                members: [],
                masses: [],
                equipment: [],
                documents: [],
                images: []
            };
            $('#newProjectModal').modal('show');
        };
        */

        $scope.showAddImagesModal = function(project) {
            $('#addImagesModal').modal('show');
        }

        $scope.showEditImageModal = function(project, image) {
            $scope.project.previewImage = {
                id: image.id,
                url: image.url,
                comment: image.description
             }
            $('#editImageModal').modal('show');
        }

        $scope.showEditProjectModal = function(project) {
            $scope.editProject = project; //TODO: Fix!
            $('#editProjectModal').modal('show');
        };

        $scope.showAddDocumentModal = function(project) {
            $scope.newDocument = {};
            $scope.newDocument.project = project;
            $('#newDocumentModal').modal('show');
        };

        $scope.showEditDocumentModal = function(project, document) {
            console.log(document);
            $scope.newDocument = document;
            $scope.newDocument.project = project;
            $scope.newDocument.filesToUpload = [];
            $('#newDocumentModal').modal('show');
        };

        $scope.showAddProductModal = function (project) {
            $scope.newProduct = {};
            $scope.newProduct.project = project;
            $scope.newProduct.momentDate = moment();
            $scope.newProduct.type = 'INTERNAL';
            $scope.newProduct.quantity = 1;
            $('#newProductModal').modal('show');
        };

        $scope.showEditProductModal = function (project, product) {
            $scope.newProduct = {};
            $scope.newProduct.id = product.id;
            $scope.newProduct.date = product.date;
            $scope.newProduct.momentDate = moment($scope.newProduct.date, 'DD.MM.YYYY');
            $scope.newProduct.name = product.name;
            $scope.newProduct.type = product.type;
            $scope.newProduct.quantity = product.quantity;
            $scope.newProduct.price = product.price;
            $scope.newProduct.project = project;
            $('#newProductModal').modal('show');
        };

        $scope.showAddProjectLogModal = function (project) {
            $scope.newProjectLog = {};
            $scope.newProjectLog.dateAndTime = moment();
            $scope.newProjectLog.project = project;
            $scope.newProjectLog.filesToUpload = [];
            $scope.newProjectLog.type = 'LOG';
            $('#newLogModal').modal('show');
        };

        $scope.showEditLogModal = function (project, log) {
            console.log(log);
            $scope.newProjectLog = log;
            $scope.newProjectLog.dateAndTime = moment($scope.newProjectLog.date, 'DD.MM.YYYY');
            $scope.newProjectLog.project = project;
            $scope.newProjectLog.filesToUpload = [];
            $scope.newProjectLog.type = 'LOG';
            $('#newLogModal').modal('show');
        };

        $scope.showAddWorkLogModal = function (project) {
            $scope.newWorkLog = {};
            var account = vm.account;
            if (account.firstName || account.lastName) {
                $scope.newWorkLog.responsible = account.firstName + ' ' + account.lastName;
            }
            $scope.newWorkLog.dateAndTime = moment();
            $scope.newWorkLog.project = project;
            $scope.newWorkLog.filesToUpload = [];
            $scope.newWorkLog.type = 'WORK_LOG';
            // Default value for log type
            $scope.newWorkLog.logType = 'Skilt satt ut';
            $('#newWorkLogModal').modal('show');
        };

        $scope.showEditWorkLogModal = function (project, log) {
            console.log(log);
            $scope.newWorkLog = log;
            $scope.newWorkLog.dateAndTime = moment($scope.newWorkLog.date, 'DD.MM.YYYY');
            $scope.newWorkLog.project = project;
            $scope.newWorkLog.filesToUpload = [];
            $scope.newWorkLog.type = 'WORK_LOG';
            $('#newWorkLogModal').modal('show');
        };

        $scope.showDeleteMemberModal = function (project, member) {
            $scope.memberToDelete = {};
            $scope.memberToDelete.name = member.name;
            $scope.memberToDelete.project = project;
            $scope.memberToDelete.memberId = member.id;
            $('#confirmDeleteMemberModal').modal('show');
        };

        $scope.showDeleteMassModal = function (project, mass) {
            $scope.massToDelete = {};
            $scope.massToDelete.name = mass.name;
            $scope.massToDelete.project = project;
            $scope.massToDelete.massId = mass.id;
            $('#confirmDeleteMassModal').modal('show');
        };

        $scope.showDeleteEquipmentModal = function (project, equipment) {
            $scope.equipmentToDelete = {};
            $scope.equipmentToDelete.name = equipment.name;
            $scope.equipmentToDelete.project = project;
            $scope.equipmentToDelete.equipmentId = equipment.id;
            $('#confirmDeleteEquipmentModal').modal('show');
        };

        $scope.showDeleteProjectModal = function (project) {
            $scope.projectToDelete = {};
            $scope.projectToDelete.project = project;
            $('#confirmDeleteProjectModal').modal('show');
        };

        $scope.showDeleteProductModal = function (project, product) {
            $scope.productToDelete = {};
            $scope.productToDelete.project = project;
            $scope.productToDelete.product = product;
            $('#confirmDeleteProductModal').modal('show');
        };

        $scope.showDeleteDocumentModal = function (project, document, isInvoice) {
            $scope.documentToDelete = {};
            $scope.documentToDelete.project = project;
            $scope.documentToDelete.document = document;
            $scope.documentToDelete.isInvoice = isInvoice;
            $('#confirmDeleteDocumentModal').modal('show');
        };

        $scope.showDeleteImageModal = function (project, previewImage) {
            $scope.imageToDelete = {};
            $scope.imageToDelete.project = project;
            $scope.imageToDelete.image = previewImage;
            $('#confirmDeleteImageModal').modal('show');
        };

        $scope.showDeleteLogModal = function (project, log) {
            $scope.logToDelete = {};
            $scope.logToDelete.project = project;
            $scope.logToDelete.log = log;
            $('#confirmDeleteLogModal').modal('show');
        };

        $scope.showDeleteWorkLogModal = function (project, log) {
            $scope.workLogToDelete = {};
            $scope.workLogToDelete.project = project;
            $scope.workLogToDelete.log = log;
            $('#confirmDeleteWorkLogModal').modal('show');
        };

        $scope.showExportProjectModal = function (project) {
            $scope.exportProject = {};
            $scope.exportProject.reportType = 'invoice';
            $scope.exportProject.includeMass = true;
            $scope.exportProject.ansvarTjeneste = true;
            $scope.exportProject.exportMethod = 'Eksporter som PDF';
            $scope.exportProject.project = project;
            $('#exportProjectModal').modal('show');
        };

        $scope.showCreateInvoiceReportModal = function () {
            var project = $scope.project;
            var from = $scope.invoiceReport.fromDate.format('YYYY-MM-DD');
            var to = $scope.invoiceReport.toDate.format('YYYY-MM-DD');
            $http({
                method: 'GET',
                url: 'api/validate/invoice/' + from + '/' + to + '/' + project.id
                + '?includeMass=' + $scope.invoiceReport.includeMass
                + '&includeProduct=' + $scope.invoiceReport.includeProduct
                + '&invoiceForExternal=' + $scope.invoiceReport.invoiceForExternal
                + '&invoiceableStatus=' + $scope.invoiceReport.invoiceableStatus
                + '&subProjectCodes=' + vm.invoicing.selectedSubProjects,
                data: null
            }).then(function(response) {
                $scope.invoiceReport.message = response.data.message;
                if (response.data.ok) {
                    $scope.invoiceReport.noneInvoicedHoursExist = response.data.noneInvoicedHoursExist;
                    $('#confirmCreateInvoiceModal').modal('show');
                } else {
                    $scope.invoiceReport.membersNotApproved = response.data.membersNotApproved;
                    $('#invoiceReportValidationModal').modal('show');
                }
            }, function (response)  {
                alert("En feil oppstod. Sjekk loggen!");
                console.log(response);
            });
        };

        $scope.toggleDisabledForList = function(list) {
            angular.forEach(list, function(value, key){
                value.options.disabled = !value.options.disabled;
                $scope.massDisabled = value.options.disabled;
            });
        };

        $scope.showAddSjaModal = function(project) {
            $scope.sja = {};
            $scope.sja.dateAndTime = moment();
            $scope.sja.project = project;
            $scope.sja.members = [];
            $('#sjaModal').modal('show');
        };

        $scope.showEditSjaModal = function(project, sja) {
            $scope.sja = {};
            $scope.sja.id = sja.id;
            $scope.sja.risk = sja.risk;
            $scope.sja.action = sja.action;
            $scope.sja.dateAndTime = moment(sja.dateAndTime, 'DD.MM.YYYY HH:mm');
            $scope.sja.members = sja.members.slice();
            $scope.sja.description = sja.description;
            $scope.sja.project = project;
            $('#sjaModal').modal('show');
        };

        $scope.saveSja = function() {
            $http({
                method: 'POST',
                url: 'api/sja',
                data: {
                    id: $scope.sja.id,
                    projectId: $scope.sja.project.id,
                    dateAndTime: $scope.sja.dateAndTime.format('YYYY-MM-DD HH:mm'),
                    risk: $scope.sja.risk,
                    action: $scope.sja.action,
                    members: $scope.sja.members,
                    description: $scope.sja.description
                }
            }).then(function(response) {
                $scope.project.sja = response.data;
                AlertService.success("SJA er lagret");
                $('#sjaModal').modal('hide');
            }, function(response) {
                $('#sjaModal').modal('hide');
                alert("En feil oppstod. Sjekk loggen!");
                console.log(response);
            });
        };

        $scope.showDeleteSjaModal = function (project, sja) {
            $scope.sjaToDelete = {};
            $scope.sjaToDelete.project = project;
            $scope.sjaToDelete.sja = sja;
            $('#confirmDeleteSjaModal').modal('show');
        };

        $scope.deleteSja = function() {
            var project = $scope.sjaToDelete.project;
            var sja = $scope.sjaToDelete.sja;
            $http({
                method: 'DELETE',
                url: 'api/sja/' + sja.id + '?projectId=' + project.id,
                data: null
            }).then(function(response) {
                project.sja = response.data;
                $('#confirmDeleteSjaModal').modal('hide');
            }, function(response) {
                alert("En feil oppstod. Sjekk loggen!");
                console.log(response);
                $('#confirmDeleteSjaModal').modal('hide');
            });
        };

        $scope.toggleMemberToSja = function(member) {
            var index = $scope.sja.members.indexOf(member.name);
            if (index > -1) {
                $scope.sja.members.splice(index, 1);
            } else {
                $scope.sja.members.push(member.name);
            }
        };

        $scope.getLabelStyle = function(member) {
            if ($scope.sja && $scope.sja.members && $scope.sja.members.indexOf(member.name) > -1) {
                return 'btn-primary';
            }
            return 'btn-default';
        };

        $scope.showMemberHoursModal = function(member) {
            $scope.memberHoursModal = {};
            $scope.memberHoursModal.year = vm.selectedYear;
            $scope.memberHoursModal.member = member;

            $http({
                method: 'GET',
                url: 'api/projects/' + $scope.project.id + '/overview/' + member.id + '/?year=' + vm.selectedYear,
                data: null
            }).then(function(response) {
                $scope.memberHoursModal.memberHours = response.data;
            }, function (response)  {
                alert("En feil oppstod. Sjekk loggen!");
                console.log(response);
            });

            $('#memberHoursModal').modal('show');
        }

        /* ========================================================================================================== */
        // BEGIN: Project budget
        /* ========================================================================================================== */

        $scope.showAddBudgetModal = function(project) {
            $scope.budget = {};
            $scope.budget.year = moment();
            $scope.budget.project = project;
            $('#budgetModal').modal('show');
        };

        $scope.showEditBudgetModal = function(project, budget) {
            $scope.budget = {};
            $scope.budget.id = budget.id;
            $scope.budget.year = moment(budget.year + '-01-31');
            $scope.budget.budgetAmount = budget.budgetAmount;
            $scope.budget.project = project;
            $('#budgetModal').modal('show');
        };

        $scope.showDeleteBudgetModal = function (project, budget) {
            $scope.budgetToDelete = {};
            $scope.budgetToDelete.project = project;
            $scope.budgetToDelete.budget = budget;
            $('#confirmDeleteBudgetModal').modal('show');
        };

        $scope.saveBudget = function() {
            $http({
                method: 'POST',
                url: 'api/budget',
                data: {
                    id: $scope.budget.id,
                    projectId: $scope.budget.project.id,
                    year: $scope.budget.year,
                    budgetAmount: $scope.budget.budgetAmount
                }
            }).then(function(response) {
                $scope.project.budgetList = response.data.budgetList;
                $scope.project.summary.budget = response.data.summaryBudget;
                AlertService.success("Budsjett er lagret");
                $('#budgetModal').modal('hide');
            }, function(response) {
                $('#budgetModal').modal('hide');
                alert("En feil oppstod. Sjekk loggen!");
                console.log(response);
            });
        };

        $scope.deleteBudget = function() {
            var project = $scope.budgetToDelete.project;
            var budget = $scope.budgetToDelete.budget;
            $http({
                method: 'DELETE',
                url: 'api/budget/' + budget.id + '?projectId=' + project.id,
                data: null
            }).then(function(response) {
                project.budgetList = response.data.budgetList;
                project.summary.budget = response.data.summaryBudget;
                $('#confirmDeleteBudgetModal').modal('hide');
            }, function(response) {
                alert("En feil oppstod. Sjekk loggen!");
                console.log(response);
                $('#confirmDeleteBudgetModal').modal('hide');
            });
        };

        /* ========================================================================================================== */
        // END: Project budget
        /* ========================================================================================================== */

        /* ========================================================================================================== */
        // START: Sub-project
        /* ========================================================================================================== */

        $scope.showAddSubProjectModal = function(project) {
            $scope.subProject = {};
            $scope.subProject.project = project;
            $scope.subProject.status = 'NEW';
            $('#subProjectModal').modal('show');
        };

        $scope.showEditSubProjectModal = function(project, subProject) {
            $scope.subProject = {};
            $scope.subProject.id = subProject.id;
            $scope.subProject.project = project;
            $scope.subProject.name = subProject.name;
            $scope.subProject.status = subProject.status;
            $scope.subProject.description = subProject.description;
            $('#subProjectModal').modal('show');
        };

        $scope.showDeleteSubProjectModal = function (project, subProject) {
            $scope.subProjectToDelete = {};
            $scope.subProjectToDelete.project = project;
            $scope.subProjectToDelete.subProject = subProject;
            $('#confirmDeleteSubProjectModal').modal('show');
        };

        $scope.saveSubProject = function() {
            $http({
                method: 'POST',
                url: 'api/sub-project',
                data: {
                    id: $scope.subProject.id,
                    projectId: $scope.subProject.project.id,
                    name: $scope.subProject.name,
                    status: $scope.subProject.status,
                    description: $scope.subProject.description
                }
            }).then(function(response) {
                $scope.project.subProject.subProjects = response.data.subProjects;
                $scope.project.subProject.activeSubProjects = response.data.activeSubProjects;
                AlertService.success("Underprosjekt er lagret");
                $('#subProjectModal').modal('hide');
            }, function(response) {
                $('#subProjectModal').modal('hide');
                alert("En feil oppstod. Sjekk loggen!");
                console.log(response);
            });
        };

        $scope.deleteSubProject = function() {
            var project = $scope.subProjectToDelete.project;
            var subProject = $scope.subProjectToDelete.subProject;
            $http({
                method: 'DELETE',
                url: 'api/sub-project/' + subProject.id + '?projectId=' + project.id,
                data: null
            }).then(function(response) {
                project.subProject.subProjects = response.data.subProjects;
                project.subProject.activeSubProjects = response.data.activeSubProjects;
                AlertService.success("Underprosjekt er slettet");
                $('#confirmDeleteSubProjectModal').modal('hide');
            }, function(response) {
                alert("En feil oppstod. Sjekk loggen!");
                console.log(response);
                $('#confirmDeleteSubProjectModal').modal('hide');
            });
        };

        /* ========================================================================================================== */
        // END: Sub-project
        /* ========================================================================================================== */

    }
})();
