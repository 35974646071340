(function() {
    'use strict';

    angular
        .module('projectManagementApp')
        .controller('SjaController', SjaController);

    SjaController.$inject = ['$scope', '$http', 'AlertService'];

    function SjaController ($scope, $http, AlertService) {
        var vm = this;

        loadData();

        function loadData() {
            $http.get('/api/sja').then(function (result) {
                vm.sjas = result.data;
            }, function (result) {
                console.log(result);
                alert('Klarte ikke laste SJA. Prøv igjen og gi beskjed til din kontaktperson ' +
                    'i DevelopIT dersom problemet vedvarer.');
            });
        }

    }
})();
