(function() {
    'use strict';

    angular
        .module('projectManagementApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider.state('user-import', {
            parent: 'app',
            url: '/bruker-import',
            data: {
                authorities: ['ROLE_ADMIN']
            },
            views: {
                'content@': {
                    templateUrl: 'app/admin/user-import/user-import.html',
                    controller: 'UserImportController',
                    controllerAs: 'vm'
                }
            }
        });
    }
})();
