(function() {
    'use strict';

    angular
        .module('projectManagementApp')
        .controller('ProjectGroupController', ProjectGroupController);

    ProjectGroupController.$inject = ['$scope', '$http', 'AlertService'];

    function ProjectGroupController ($scope, $http, AlertService) {
        var vm = this;

        // Filters for the project explorer.
        vm.statusFilter = 'IN_PROGRESS';
        vm.typeFilter = 'NONE';
        vm.searchFilter = 'ALLE';
        vm.modalFilterString = '';

        loadProjects();
        loadData();

        /* === AngularJS functions (accessible from view) =========================================================== */

        // Invoked every time the filter changes.
        vm.filterChanged = function() {
            loadData();
        };

        // Show the modal for adding project to a group.
        $scope.showAddProjectToGroupModal = function(group) {
            $scope.group = group;
            $scope.selectedProjects = [];
            $('#addProjectToGroup').modal('show');
        }

        // Show the modal for removing project from project group.
        $scope.showDeleteProjectGroupModal = function(projectGroup) {
            $('#confirmDeleteProjectGroup').modal('show');
            $scope.projectGroup = projectGroup;
        }

        // Toggle select og project in "project explorer".
        vm.toggleSelected = function(project) {
            if (project.isSelected === null) {
                project.isSelected = true;
            } else {
                project.isSelected = !project.isSelected;
            }

            if (project.isSelected) {
                $scope.selectedProjects.push(project);
            } else {
                var index = $scope.selectedProjects.indexOf(project);
                $scope.selectedProjects.splice(index, 1);
            }
        };

        // Save project group.
        $scope.saveProjectGroup = function() {
            var projectIds = [];
            angular.forEach($scope.selectedProjects, function(value, key){
                projectIds.push(value.id);
                value.isSelected = false;
            });

            $http({
                method: 'POST',
                url: 'api/project-group/create',
                data: {
                    projectIds: projectIds,
                    projectGroup: $scope.group
                }
            }).then(function(response) {
                $('#addProjectToGroup').modal('hide');
                vm.modalFilterString = '';
                loadData();
                AlertService.success('Driftsavtale oppdatert')
            }, function(response) {
                alert("En feil oppstod. Sjekk loggen!");
                console.log(response);
            });
        }

        // Clear selection and search.
        $scope.clear = function() {
            angular.forEach($scope.selectedProjects, function(value, key){
                value.isSelected = false;
            });
            vm.modalFilterString = '';
        }

        // Remove project from project group.
        $scope.removeProjectFromProjectGroup = function() {
            $http({
                method: 'POST',
                url: 'api/project-group/' + $scope.projectGroup.projectId + '/clear'
            }).then(function(response) {
                $('#confirmDeleteProjectGroup').modal('hide');
                loadData();
                AlertService.success('Prosjekt fjernet fra driftsavtale')
            }, function(response) {
                alert("En feil oppstod. Sjekk loggen!");
                console.log(response);
            });
        }

        /* === Functions ============================================================================================ */

        // Load projects from server with given filter.
        function loadProjects() {
            var url = '/api/all/projects?status=' + vm.statusFilter
                + '&filterLetter=' + vm.searchFilter
                + '&type=' + vm.typeFilter;
            $http.get(url).then(function (result) {
                vm.projects = result.data;
            }, function (result) {
                console.log(result);
                alert('Klarte ikke laste prosjektene. Prøv igjen og gi beskjed til din kontaktperson ' +
                    'i DevelopIT dersom problemet vedvarer.');
            });
        }

        // Load data from server.
        function loadData() {
            $http.get('/api/project-group/all').then(function (result) {
                vm.projectGroups = result.data;
            }, function (result) {
                console.log(result);
                alert('Klarte ikke laste driftsavtaler. Prøv igjen og gi beskjed til din kontaktperson ' +
                    'i DevelopIT dersom problemet vedvarer.');
            });
        }
    }
})();
