(function() {
    'use strict';

    angular
        .module('projectManagementApp')
        .controller('FavouriteEquipmentController', FavouriteEquipmentController);

    FavouriteEquipmentController.$inject = ['$http'];

    function FavouriteEquipmentController ($http) {
        var vm = this;

        loadData();

        vm.toggleFavourite = function(equipment) {
            var isFavourite;
            if (equipment.isFavourite === null) {
                isFavourite = true;
            } else {
                isFavourite = !equipment.isFavourite;
            }

            $http({
                method: 'POST',
                url: 'api/favourite/equipment/' + equipment.id + '/' + isFavourite
            }).then(function(response) {
                equipment.isFavourite = isFavourite;
            }, function(response) {
                alert("En feil oppstod. Sjekk loggen!");
                console.log(response);
            });
        };

        vm.filterChanged = function() {
            loadData();
        };

        function loadData() {
            $http.get('/api/all/equipment').then(function (result) {
                vm.equipments = result.data;
            }, function (result) {
                console.log(result);
                alert('Klarte ikke laste utstyr. Prøv igjen og gi beskjed til din kontaktperson ' +
                    'i DevelopIT dersom problemet vedvarer.');
            });
        }
    }
})();
