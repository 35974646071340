/* eslint-disable angular/controller-as,no-undef,angular/log */
(function() {
    'use strict';

    angular
        .module('projectManagementApp')
        .controller('TimereportController', TimereportController);

    TimereportController.$inject = ['$scope', '$http', 'AlertService'];

    function TimereportController ($scope, $http, AlertService) {
        var vm = this;
        vm.currentDate = moment();
        vm.ansvarFilter = {
            ansvar: ''
        };

        loadData(vm.currentDate);

        $scope.setDateTime = function () {
            $("#datetimepickerStart").datetimepicker().on("dp.change", function (data) {
                vm.currentDate = data.date;
                loadData(data.date);
            });
        };

        $scope.previousWeek = function() {
            vm.currentDate = vm.currentDate.add(-1, 'week');
            loadData(vm.currentDate);
            $('#datetimepickerStart').data("DateTimePicker").date(vm.currentDate);
        };

        $scope.thisWeek = function() {
            vm.currentDate = moment();
            loadData(vm.currentDate);
            $('#datetimepickerStart').data("DateTimePicker").date(vm.currentDate);
        };

        $scope.nextWeek = function() {
            vm.currentDate = vm.currentDate.add(1, 'week');
            loadData(vm.currentDate);
            $('#datetimepickerStart').data("DateTimePicker").date(vm.currentDate);
        };

        $scope.showRejectTimesheetModal = function(projectMember) {
            vm.reject = {};
            vm.reject.message = '';
            vm.reject.projectMember = projectMember;
            $('#confirmRejectTimesheetModal').modal('show');
        };

        $scope.rejectTimesheet = function() {
            $http({
                method: 'POST',
                url: 'api/timereport/reject/' + vm.reject.projectMember.memberId + '/' + vm.currentDate.format('DD-MM-YYYY'),
                data: {
                    message: vm.reject.message
                }
            }).then(function(response) {
                $('#confirmRejectTimesheetModal').modal('hide');
                loadData(vm.currentDate);
            }, function(response) {
                $('#confirmRejectTimesheetModal').modal('hide');
                alert("Klarte ikke å avvise timene. Sjekk loggen!");
                console.log(response);
            });
        };

        $scope.approveTimesheet = function (projectMember) {
            $http({
                method: 'POST',
                url: 'api/timereport/approve/' + projectMember.memberId + '/' + vm.currentDate.format('DD-MM-YYYY')
            }).then(function() {
                AlertService.info("Timene for " + projectMember.name + " er nå godkjent.");
                loadData(vm.currentDate);
            }, function(response) {
                alert("Klarte ikke å godkjenne timene. Sjekk loggen!");
                console.log(response);
            });
        };

        function loadData(date) {
            $http.get('/api/timereport/' + date.format('DD-MM-YYYY')).then(function (result) {
                vm.timereport = result.data;
            }, function (result) {
                console.log(result);
                alert('Klarte ikke laste timene for denne uken. Prøv igjen og gi beskjed til din kontaktperson ' +
                    'i DevelopIT dersom problemet vedvarer.');
            });
        }
    }
})();
