/* eslint-disable no-undef,angular/controller-as,angular/log */
(function() {
    'use strict';

    angular
        .module('projectManagementApp')
        .controller('EquipmentReportController', EquipmentReportController);

    EquipmentReportController.$inject = ['$scope', '$http'];

    function EquipmentReportController ($scope, $http) {
        var vm = this;

        vm.inAmount = true;
        vm.selectedYear = moment().year().toString();

        vm.ansvarFilter = {
            ansvar: ''
        };

        loadData();

        vm.refresh = function () {
            loadData();
        };

        function loadData() {
            $http.get('/api/equipment/report?inAmount=' + vm.inAmount + '&selectedYear=' + vm.selectedYear).then(function (result) {
                vm.equipmentReport = result.data;
            }, function (result) {
                console.log(result);
                alert('Klarte ikke laste Utstyrsrapport. Prøv igjen og gi beskjed til din kontaktperson ' +
                    'i DevelopIT dersom problemet vedvarer.');
            });
        }
    }
})();
