(function() {
    'use strict';

    angular
        .module('projectManagementApp')
        .controller('InvoiceListController', InvoiceListController);

    InvoiceListController.$inject = ['$scope', '$http'];

    function InvoiceListController ($scope, $http) {
        var vm = this;

        vm.monthFilter = moment().month();

        loadData(vm.monthFilter);

        vm.updateFilter = function() {
            loadData(vm.monthFilter);
        };

        function loadData(month) {
            var url = '/api/invoice/list';
            if (month >= 0) {
                url += '?month=' + month;
            }
            $http.get(url).then(function (result) {
                vm.invoiceList = result.data;
            }, function (result) {
                console.log(result);
                alert('Klarte ikke laste fakturaene. Prøv igjen og gi beskjed til din kontaktperson ' +
                    'i DevelopIT dersom problemet vedvarer.');
            });
        }

    }
})();
