(function() {
    'use strict';

    angular
        .module('projectManagementApp')
        .controller('FavouriteController', FavouriteController);

    FavouriteController.$inject = ['$http'];

    function FavouriteController ($http) {
        var vm = this;

        vm.statusFilter = 'IN_PROGRESS';
        vm.typeFilter = 'NONE';
        vm.searchFilter = 'ALLE';
        vm.filterLetters = ['ALLE', 'A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P',
            'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z', 'Æ', 'Ø', 'Å'];

        loadData();

        vm.applyFilter = function(letter) {
            vm.searchFilter = letter;
            loadData();
        };

        vm.toggleFavourite = function(project) {
            var isFavourite;
            if (project.isFavourite === null) {
                isFavourite = true;
            } else {
                isFavourite = !project.isFavourite;
            }

            $http({
                method: 'POST',
                url: 'api/favourite/project/' + project.id + '/' + isFavourite
            }).then(function(response) {
                project.isFavourite = isFavourite;
            }, function(response) {
                alert("En feil oppstod. Sjekk loggen!");
                console.log(response);
            });
        };

        vm.filterChanged = function() {
            loadData();
        };

        function loadData() {
            var url = '/api/all/projects?status=' + vm.statusFilter
                + '&filterLetter=' + vm.searchFilter
                + '&type=' + vm.typeFilter;
            $http.get(url).then(function (result) {
                vm.projects = result.data;
            }, function (result) {
                console.log(result);
                alert('Klarte ikke laste prosjektene. Prøv igjen og gi beskjed til din kontaktperson ' +
                    'i DevelopIT dersom problemet vedvarer.');
            });
        }
    }
})();
