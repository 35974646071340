(function() {
    'use strict';

    angular
        .module('projectManagementApp', [
            'ngStorage',
            'ngResource',
            'ngCookies',
            'ngAria',
            'ngCacheBuster',
            'ngFileUpload',
            'ui.bootstrap',
            //'ui.bootstrap.datetimepicker',
            'ui.router',
            'ui.router.state.events',
            'rzModule',
            // jhipster-needle-angularjs-add-module JHipster will add new module here
            'angular-loading-bar',
            'moment-picker',
            'ngMap',
            'chart.js'
        ])
        .run(run);

    run.$inject = ['stateHandler', '$rootScope'];

    function run(stateHandler, $rootScope) {
        stateHandler.initialize();

        // Always scroll to top of screen on page changes.
        // eslint-disable-next-line angular/on-watch
        $rootScope.$on('$stateChangeSuccess', function() {
            // eslint-disable-next-line angular/document-service
            document.body.scrollTop = document.documentElement.scrollTop = 0;

            // Get tenant claim from JWT token and put on root scope. Default is BYDRIFT_AND_VA. Note that we do this
            // on stateChangeSuccess in order to decode token on each page change (when switching between tenants).
            var decodedToken = decodeJWT();
            if (decodedToken && decodedToken.tenant) {
                $rootScope.tenant = decodedToken.tenant;
            } else {
                $rootScope.tenant = 'BYDRIFT_AND_VA';
            }
        });
    }

    // Extract claims from JWT token.
    function decodeJWT() {
        var token = localStorage.getItem('jhi-authenticationToken');
        if (!token) {
            return null;
        }

        // Split the JWT token into its parts
        var tokenParts = token.split('.');
        if (tokenParts.length !== 3) {
            console.error('Invalid JWT token format');
            return null;
        }

        // Base64-decode the payload part (second part)
        var base64Url = tokenParts[1];
        var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
        var jsonPayload = decodeURIComponent(atob(base64).split('').map(function(c) {
            return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
        }).join(''));

        // Parse the JSON payload
        try {
            return JSON.parse(jsonPayload);
        } catch (e) {
            console.error('Failed to decode JWT payload', e);
            return null;
        }
    }
})();
