(function() {
    'use strict';

    angular
        .module('projectManagementApp')
        .controller('FamacController', FamacController);

    FamacController.$inject = ['$scope', '$http', 'AlertService'];

    function FamacController ($scope, $http, AlertService) {
        var vm = this;
        vm.currentDate = moment().subtract(2, 'weeks');
        vm.ignoreChangedActivityId = false;

        loadData();

        function loadData() {
            $http.get('/api/famac/dashboard').then(function (result) {
                vm.dashboard = result.data;
                if (result.data.status === 'OK') {
                    vm.dashboard.style = 'background-color: #5cb85c; color: white';
                } else {
                    vm.dashboard.style = 'background-color: #f0ad4e';
                }
            }, function (result) {
                console.log(result);
                alert('Klarte ikke laste dashboard. Prøv igjen og gi beskjed til din kontaktperson ' +
                    'i DevelopIT dersom problemet vedvarer.');
            });
        }

        $scope.stagingImport = function() {
            $http.get('/api/famac/stagingImport').then(function (result) {
                loadData();
            }, function (result) {
                console.log(result);
                alert('Klarte ikke laste ned data fra Famac. Prøv igjen og gi beskjed til din kontaktperson ' +
                    'i DevelopIT dersom problemet vedvarer.');
            });
        };

        $scope.import = function() {
            $http.get('/api/famac/import/?ignoreChangedActivityId=' + vm.ignoreChangedActivityId).then(function (result) {
                loadData();
            }, function (result) {
                console.log(result);
                alert('Klarte ikke laste inn data fra Famac. Prøv igjen og gi beskjed til din kontaktperson ' +
                    'i DevelopIT dersom problemet vedvarer.');
            });
        };

        $scope.setDate = function () {
            $("#datetimepickerStart").datetimepicker().on("dp.change", function (data) {
                vm.currentDate = data.date;
            });
        };

    }
})();
