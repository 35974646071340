(function() {
    'use strict';

    angular
        .module('projectManagementApp')
        .controller('InvoiceController', InvoiceController);

    InvoiceController.$inject = ['$scope', '$http'];

    function InvoiceController ($scope, $http) {
        var vm = this;

        loadData();

        function loadData() {
            $http.get('/api/project/invoice').then(function (result) {
                vm.projectInvoices = result.data;
            }, function (result) {
                console.log(result);
                alert('Klarte ikke laste brukere. Prøv igjen og gi beskjed til din kontaktperson ' +
                    'i DevelopIT dersom problemet vedvarer.');
            });
        }

    }
})();
