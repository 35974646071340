(function() {
    'use strict';

    angular
        .module('projectManagementApp')
        .controller('GpsItemAdminController', GpsItemAdminController);

    GpsItemAdminController.$inject = ['$scope', '$http', 'AlertService'];

    function GpsItemAdminController ($scope, $http, AlertService) {
        var vm = this;

        loadData();

        function loadData() {
            // Do not fucking use let here. Will completely crash when running in PROD profile.
            $http.get('/api/gpsitem').then(function (result) {
                vm.gpsItem = result.data;
            }, function (result) {
                console.log(result);
                alert('Klarte ikke laste molok. Prøv igjen og gi beskjed til din kontaktperson ' +
                    'i DevelopIT dersom problemet vedvarer.');
            });
        }

        $scope.showGpsItemModal = function(gpsItem) {
            // Polyfill (to make the Object.assign work on IE) snatched from here:
            // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Object/assign
            if (typeof Object.assign != 'function') {
                // Must be writable: true, enumerable: false, configurable: true
                Object.defineProperty(Object, "assign", {
                    value: function assign(target, varArgs) { // .length of function is 2
                        'use strict';
                        if (target == null) { // TypeError if undefined or null
                            throw new TypeError('Cannot convert undefined or null to object');
                        }

                        var to = Object(target);

                        for (var index = 1; index < arguments.length; index++) {
                            var nextSource = arguments[index];

                            if (nextSource != null) { // Skip over if undefined or null
                                for (var nextKey in nextSource) {
                                    // Avoid bugs when hasOwnProperty is shadowed
                                    if (Object.prototype.hasOwnProperty.call(nextSource, nextKey)) {
                                        to[nextKey] = nextSource[nextKey];
                                    }
                                }
                            }
                        }
                        return to;
                    },
                    writable: true,
                    configurable: true
                });
            } // End polyfill

            $scope.gpsItem = {};
            if (!gpsItem) {
                $scope.gpsItem.flag = false;
                $scope.gpsItem.type = 'MOLOK';
            }
            Object.assign($scope.gpsItem, gpsItem);
            $('#gpsItemModal').modal('show');
        };

        $scope.saveGpsItem = function() {
            $http({
                method: 'POST',
                url: 'api/gpsitem',
                data: $scope.gpsItem
            }).then(function (response) {
                if ($scope.gpsItem.id) {
                    AlertService.success("Molok oppdatert.");
                } else {
                    AlertService.success("Ny molok opprettet.");
                }
                loadData();
                $('#gpsItemModal').modal('hide');
            }, function (response) {
                alert("En feil oppstod. Sjekk loggen!");
                console.log(response);
                $('#gpsItemModal').modal('hide');
            });
        };

    }
})();
