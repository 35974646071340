(function() {
    'use strict';

    angular
        .module('projectManagementApp')
        .controller('InvoiceApprovalController', InvoiceApprovalController);

    InvoiceApprovalController.$inject = ['$scope', '$http', 'AlertService'];

    function InvoiceApprovalController ($scope, $http, AlertService) {
        var vm = this;

        vm.accountEntriesAreNotValidatedOk = true;

        // Find first and last date of previous month.
        var periodStartDate = moment()
            .subtract(1,'months')
            .startOf('month');
        var periodEndDate = moment()
            .subtract(1,'months')
            .endOf('month');

        $('#periodStartDateDropdown').datetimepicker({
            format: 'DD.MM.YYYY',
            locale: 'nb',
            defaultDate: periodStartDate
        });

        $('#periodEndDateDropdown').datetimepicker({
            format: 'DD.MM.YYYY',
            locale: 'nb',
            defaultDate: periodEndDate
        });

        loadData(periodStartDate, periodEndDate);

        $scope.setPeriodStartDate = function () {
            $("#periodStartDateDropdown").datetimepicker().on("dp.change", function (data) {
                loadData(data.date, periodEndDate);
            });
        };

        $scope.setPeriodEndDate = function () {
            $("#periodEndDateDropdown").datetimepicker().on("dp.change", function (data) {
                loadData(periodStartDate, data.date);
            });
        };

        $scope.isCollapsed = function (index) {
            // TODO: Improve this.
            var cAttribute = $("#collapse" + (index + 1)).attr("class");
            if (cAttribute) {
                return cAttribute.indexOf('in') >= 0;
            }

            return null;
        };

        $scope.loadProjectDetails = function (index, project) {
            // Only get accounting details when opening the collapsable panel.
            if (!$scope.isCollapsed(index)) {
                $http.get('/api/project/invoice/accounting/'
                        + project.projectId + '/'
                        + project.periodFrom + '/'
                        + project.periodTo).then(function (result) {
                    project.accounting = result.data;
                }, function (result) {
                    console.log(result);
                    alert('Klarte ikke laste posteringer for prosjektet. Prøv igjen og gi beskjed til din ' +
                        'kontaktperson i DevelopIT dersom problemet vedvarer.');
                });
            }
        };

        $scope.validateAccountingEntries = function (projectId) {
            $http.get('/api/project/invoice/accounting/validate/'
                + projectId + '/'
                + periodStartDate.format("YYYY-MM-DD") + '/'
                + periodEndDate.format("YYYY-MM-DD")).then(function (result) {
                    if (result.data.errorFlag === 'OK') {
                        alert('Validering OK. Det er nå mulig å sende til Visma.');
                        vm.accountEntriesAreNotValidatedOk = false;
                    } else {
                        alert('Validering feilet. Dette må undersøkes før det vil være mulig å sende til Visma.');
                    }
            }, function (result) {
                console.log(result);
                alert('Klarte ikke laste fakturagodkjenning. Prøv igjen og gi beskjed til din kontaktperson ' +
                    'i DevelopIT dersom problemet vedvarer.');
            });
        };

        $scope.sendAccountEntriesToVisma = function () {
            alert('Not implemented yet!');
        };

        function loadData(periodStartDate, periodEndDate) {
            $http.get('/api/project/invoice/approval/'
                    + periodStartDate.format("YYYY-MM-DD") + '/'
                    + periodEndDate.format("YYYY-MM-DD")).then(function (result) {
                vm.approvals = result.data;
            }, function (result) {
                console.log(result);
                alert('Klarte ikke laste fakturagodkjenning. Prøv igjen og gi beskjed til din kontaktperson ' +
                    'i DevelopIT dersom problemet vedvarer.');
            });
        }
    }
})();
