(function() {
    'use strict';

    angular
        .module('projectManagementApp')
        .controller('EquipmentController', EquipmentController);

    EquipmentController.$inject = ['$scope', '$http', 'AlertService'];

    function EquipmentController ($scope, $http, AlertService) {
        var vm = this;

        loadData();

        function loadData() {
            $http.get('/api/availableEquipment').then(function (result) {
                vm.equipments = result.data.allEquipment;
            }, function (result) {
                console.log(result);
                alert('Klarte ikke laste utstyr. Prøv igjen og gi beskjed til din kontaktperson ' +
                    'i DevelopIT dersom problemet vedvarer.');
            });
        }

        $scope.saveEquipment = function() {
            $http({
                method: 'POST',
                url: 'api/equipment',
                data: $scope.modalEquipment
            }).then(function(response) {
                if ($scope.modalEquipment.id) {
                    AlertService.success("Bil/utstyr er oppdatert.");
                } else {
                    AlertService.success("Ny Bil/utstyr opprettet.");
                }
                $('#equipmentModal').modal('hide');
                loadData();
            }, function(response) {
                alert("En feil oppstod. Sjekk loggen!");
                console.log(response);
            });
        };

        $scope.showEquipmentModal = function(equipment) {
            // Polyfill (to make the Object.assign work on IE) snatched from here:
            // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Object/assign
            if (typeof Object.assign != 'function') {
                // Must be writable: true, enumerable: false, configurable: true
                Object.defineProperty(Object, "assign", {
                    value: function assign(target, varArgs) { // .length of function is 2
                        'use strict';
                        if (target == null) { // TypeError if undefined or null
                            throw new TypeError('Cannot convert undefined or null to object');
                        }

                        var to = Object(target);

                        for (var index = 1; index < arguments.length; index++) {
                            var nextSource = arguments[index];

                            if (nextSource != null) { // Skip over if undefined or null
                                for (var nextKey in nextSource) {
                                    // Avoid bugs when hasOwnProperty is shadowed
                                    if (Object.prototype.hasOwnProperty.call(nextSource, nextKey)) {
                                        to[nextKey] = nextSource[nextKey];
                                    }
                                }
                            }
                        }
                        return to;
                    },
                    writable: true,
                    configurable: true
                });
            }

            $scope.modalEquipment = {};
            Object.assign($scope.modalEquipment, equipment);
            $('#equipmentModal').modal('show');
        }

    }
})();
