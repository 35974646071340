(function() {
    'use strict';

    angular
        .module('projectManagementApp')
        .controller('MapController', MapController);

    MapController.$inject = ['NgMap', '$http'];

    function MapController (NgMap, $http) {
        var vm = this;

        loadData();

        NgMap.getMap().then(function(map) {
            vm.map = map;
        });

        vm.showInfoWindow = function(event, projectId) {
            vm.project = vm.projects[projectId];
            vm.map.showInfoWindow('infoWindow', this);
        };

        function loadData() {
            $http.get('/api/map/projects').then(function(result) {
                vm.projects = result.data;
                angular.forEach(vm.projects, function (value, key) {
                    if (value.status === 'IN_PROGRESS') {
                        value.icon = 'content/images/sk_marker.png';
                    } else {
                        value.icon = 'content/images/sk_marker_bw.png';
                    }
                });
            });
        }


    }
})();
